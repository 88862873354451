import { Card, Popover, Tooltip } from '@mui/material';
import { currencyList, employmentTypes } from 'containers/HiringManager/Jobs.util';
import { compensationOptions } from 'pages/B2B/constants';
import CreateJobModalContainer from 'pages/B2B/JobManagement/Containers/CreateJobModalContainer';
import AddCommentModal from 'pages/B2B/Jobs/AddComment/AddComment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { Chat, Tools } from 'styled-icons/bootstrap';
import { Note } from 'styled-icons/boxicons-regular';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import { formatLocation } from '../utils/util';
import JobDescriptionModal from './JobDescriptionModal';
import { formatLabel } from 'utilities/utils';

const JobDetailsContainer = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 0px 12px;
    border-bottom: 1px solid #E0E0E0;
`;

const JobInfo = styled.div`
    display: flex;
    gap: 32px;
`;

const JobInfoItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
    span:first-child {
        font-weight: 500;
        color: #757575;  
    }
    span:last-child {
        font-weight: 600;
        color: #333333;  
    }
`;

const JobInfoItemSalary = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    span {
        font-weight: 600 !important;
        color: #333333 !important;  
    }
`;

const JobActions = styled.div`
    display: flex;
    gap: 12px;  
    align-items: center;
    svg {
        width: 16px;  
        height: 16px;
        color: #2979FF;  
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
            color: #1a5fb4;  
        }
    }
`;

const JobDetails: React.FC = () => {
    const { currentJob, fetchJobById } = useJobPipeline();
    const [showEditJob, setShowEditJob] = useState(false);
    const [addCommentPopover, setAddCommentPopover] = useState<null | HTMLElement>(null);
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [showJobDescription, setShowJobDescription] = useState(false);

    if (!currentJob) return null;

    const currency = currencyList.find(c => c.code === currentJob.currency);

    const handleEditJobClick = () => {
        setShowEditJob(true);
    };

    const handleChatClick = (event: any) => {
        setAddCommentPopover(event.currentTarget);
    };

    const handleAfterJobEdit = async () => {
        setShowEditJob(false);
        await fetchJobById(currentJob.jobId);
    };

    const handleJobDescriptionClick = () => {
        setShowJobDescription(true);
    };


    return (
        <JobDetailsContainer>
            <JobInfo>
                <JobInfoItem>
                    <span>Company:</span>
                    <span>{currentJob.companyInfo?.companyName || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    {currentJob.companyInfo?.companyLogo ? (
                        <img
                            height={20}
                            style={{ objectFit: 'scale-down' }}
                            src={currentJob.companyInfo?.companyLogo}
                            alt={currentJob.companyInfo?.companyName}
                        />
                    ) : (
                        <span>""</span>
                    )}
                    <span>{currentJob.expertInfo?.fullName || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Job Type:</span>
                    <span>{currentJob.jobType || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Location:</span>
                    <span>{currentJob.locations ? formatLocation(currentJob.locations) : "-"}</span>
                </JobInfoItem>
                {currentJob.jobType === "REMOTE" && (
                    <JobInfoItem>
                        <span>Time Zone:</span>
                        <span>{formatLabel(currentJob.timezone || "-")}</span>
                    </JobInfoItem>
                )}
                <JobInfoItem>
                    <span>Employment Type:</span>
                    <span>
                        {employmentTypes.find(e => e.key === currentJob.employmentType)?.value || "-"}
                    </span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Salary:</span>
                    <JobInfoItemSalary>
                        <span>{(currentJob.minSalary ? (currency?.icon || "") : "") + (currentJob.minSalary || "-")}</span>
                        <span>-</span>
                        <span>{(currentJob.maxSalary ? (currency?.icon || "") : "") + (currentJob.maxSalary || "-")}</span>
                    </JobInfoItemSalary>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Compensation:</span>
                    <span>
                        {compensationOptions.find(c => c.key === currentJob.equity)?.label || "-"}
                    </span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Open Positions:</span>
                    <span>{currentJob.openPositions || "-"}</span>
                </JobInfoItem>
                <JobInfoItem>
                    <span>Rejected:</span>
                    <span>{currentJob.rejectedCandidates || "-"}</span>
                </JobInfoItem>
            </JobInfo>
            <JobActions>
                {currentJob.jobAccess != 'PUBLIC' &&
                <Tooltip title="Edit Jobs">
                    <Tools onClick={handleEditJobClick} />
                </Tooltip>}
                <Tooltip title="Job Description">
                    <Note onClick={handleJobDescriptionClick} />
                </Tooltip>
                <Tooltip title="Comments">
                    <Chat onClick={handleChatClick} />
                </Tooltip>
            </JobActions>
            {showEditJob &&
                <CreateJobModalContainer
                    setShowCreateJob={() => setShowEditJob(false)}
                    handleAfterJobSave={handleAfterJobEdit}
                    mode="EDIT_JOB"
                    jobDetails={currentJob as any}
                />
            }

            {showJobDescription && <JobDescriptionModal
                open={showJobDescription}
                onClose={() => setShowJobDescription(false)}
                job={currentJob}
            />}

            <Popover
                open={Boolean(addCommentPopover)}
                anchorEl={addCommentPopover}
                onClose={() => setAddCommentPopover(null)}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: 'left',
                }}
            >
                <AddCommentModal
                    expertId={expertId}
                    jobId={currentJob.jobId}
                    ownerExpert={{ fullname: currentJob?.expertInfo?.fullName || "", expertId: currentJob?.expertInfo?.expertId || "", email: currentJob?.expertInfo?.email || "" }}
                />
            </Popover>
        </JobDetailsContainer>
    );
};

export default JobDetails;