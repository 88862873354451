import Alert from '@mui/material/Alert';
import RichTextEditor from "components/Common/Editors/RichTextEditor";
import { ModalComponent } from "components/Modals/Modal";
import { CalendlyPopup } from "containers/CalendlyPopup";
import { CreateCandidateMeetingRequest } from "containers/Candidate/ICandidate";
import ExpertProducts from "containers/ExpertProducts";
import { ResumeReview } from "containers/ResumeReview";
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Loader from "react-spinners/ClipLoader";
import { RootState } from "store";
import { ExpertInfoType, ProductInfoType } from "types/Jobs";
import { getUniqueId } from "utilities/commonUtils";
import { HM_EMAIL } from "utilities/constants";
import { CreateCandidate } from "./CreateCandidate";
import TrackTreeViewer from "./TrackTreeViewer";
import { jobsService } from 'services/jobs';
import { toast } from 'react-toastify';

type props = {
    setShowCreateCandidate: React.Dispatch<React.SetStateAction<boolean>>;
    jobId: string;
    trackId: string;
    startWorkFlow: CreateCandidateWorkflowSteps;
    handleMeetingBooked: (payload: CreateCandidateMeetingRequest) => void;
    selectedExpert?: ExpertInfoType;
    selectedProduct?: ProductInfoType;
    resumeUrl?: string;
    candidateNotes?: string;
    selectedQuestions?: any[];
    updateCandidateResume?: (candidateState: any, candidateId: string, url: string) => void;
}

export enum CreateCandidateWorkflowSteps {
    CREATE_CANDIDATE,
    SELECT_PRODUCT,
    SELECT_RESUME,
    SELECT_QUESTIONS,
    BOOK_CALENDLY_MEETING,
    MEETING_BOOKED_WAITING_FOR_RESULT
}

export const CreateCandidateWorkFlow = ({
    setShowCreateCandidate,
    jobId,
    trackId,
    startWorkFlow,
    handleMeetingBooked,
    ...props }: props) => {

    const [currStep, setCurrStep] = useState<CreateCandidateWorkflowSteps>(startWorkFlow); // startWorkFlow
    const [selectedExpert, setSelectedExpert] = useState<ExpertInfoType | undefined>(props.selectedExpert);
    const [selectedProduct, setSelectedProduct] = useState<ProductInfoType | undefined>(props.selectedProduct);
    const [resumeUrl, setResumeUrl] = useState<string>(props.resumeUrl ? props.resumeUrl : "");
    const [candidateNotes, setCandidateNotes] = useState<string>(props.candidateNotes ? props.candidateNotes : "");
    const [selectedQuestions, setSelectedQuestions] = useState<any[]>(props.selectedQuestions ? props.selectedQuestions : []);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const { selectedCandidateForBookMeetingProcess } = useSelector((state: RootState) => state.jobs);
    const expert = useSelector((state: RootState) => state.auth.user);
    const uniqueCandidateName = useRef("");
    let meetingGuests = [expert.email, HM_EMAIL];

    useEffect(() => {
        uniqueCandidateName.current = `${selectedCandidateForBookMeetingProcess?.fullname!} {Meeting Id-${getUniqueId(5)}}`;
    }, [selectedCandidateForBookMeetingProcess])

    useEffect(() => {
        setErrorMsg(null);
    }, [currStep])

    const triggerWorkflow = () => {
        if (validateStep()) {
            setCurrStep(currStep + 1);
        }
    }

    const triggerWorkflowRev = () => {
        if (currStep === CreateCandidateWorkflowSteps.SELECT_QUESTIONS) {
            setSelectedQuestions([]);
            setCandidateNotes("");
        }
        if (currStep === CreateCandidateWorkflowSteps.SELECT_RESUME) {
            setResumeUrl("");
        }
        setCurrStep(currStep - 1);
    }

    const validateStep = (): boolean => {
        if (currStep === CreateCandidateWorkflowSteps.SELECT_RESUME && !resumeUrl) {
            setErrorMsg("Please select resume to continue.");
            removeErrorMessageAfterInterval();
            return false;
        }
        return true;
    }

    const removeErrorMessageAfterInterval = () => {
        setTimeout(() => { setErrorMsg(null) }, 3000);
    }

    const updateResumeUrl = (url: string) => {
        setResumeUrl(url);
        jobsService.updateCandidateDetails(expert.expertId, selectedCandidateForBookMeetingProcess?.candidateId!, url);
        props.updateCandidateResume && props.updateCandidateResume("TALENT_POOL", selectedCandidateForBookMeetingProcess?.candidateId!, url);
    }

    const getSectionToShow = () => {
        if (currStep === CreateCandidateWorkflowSteps.CREATE_CANDIDATE) {
            return <CreateCandidate
                setShowCreateCandidate={setShowCreateCandidate}
                jobId={jobId}
                triggerWorkflow={triggerWorkflow}
                candidateId={selectedCandidateForBookMeetingProcess?.candidateId!}
                defaultResumeURL={props.resumeUrl ?? ''}
                trackId={trackId}
                updateResumeUrl={updateResumeUrl}
            />
        }
        if (currStep === CreateCandidateWorkflowSteps.SELECT_PRODUCT) {
            return <ExpertProducts
                setSelectedExpert={setSelectedExpert}
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
                triggerWorkflow={triggerWorkflow}
                trackId={trackId}
            />
        }
        if (currStep === CreateCandidateWorkflowSteps.SELECT_RESUME) {
            return <div>
                <div className="h5 m-3 text-center">
                    <span>{'Select Resume'}</span>
                </div>
                <ResumeReview
                    resumeUrl={resumeUrl}
                    setResumeUrl={updateResumeUrl}
                    trackId={trackId}
                    candidateId={selectedCandidateForBookMeetingProcess?.candidateId!}

                />
            </div>
        }
        if (currStep === CreateCandidateWorkflowSteps.SELECT_QUESTIONS) {
            return (
                <>
                    <TrackTreeViewer trackId={trackId} setSelectedQuestions={setSelectedQuestions} />
                    <div>
                        <div className="h6 m-3 text-center">
                            <span>{'Enter questions or notes you want to share with Expert'}</span><span className="text-danger">(Optional)</span>
                        </div>
                        <RichTextEditor
                            disabled={false}
                            id={`notesForExpert`}
                            value={candidateNotes}
                            onChange={setCandidateNotes}
                            customStyles={{ height: '120px', boxShadow: 'none', border: '1px solid #ccc' }}
                        />
                    </div>
                </>
            )
        }
        if (currStep === CreateCandidateWorkflowSteps.BOOK_CALENDLY_MEETING) {
            return (
                <CalendlyPopup
                    onConfirm={handleConfirmMeeting}
                    details={{
                        cUrl: selectedExpert?.expert?.calendlyURL!,
                        email: selectedCandidateForBookMeetingProcess?.email!,
                        name: uniqueCandidateName.current
                    }}
                    meetingGuests={meetingGuests}
                />
            )
        }
        if (currStep === CreateCandidateWorkflowSteps.MEETING_BOOKED_WAITING_FOR_RESULT) {
            return (
                <div className='d-flex align-items-center'>
                    <Loader loading={true} size={20} color={'blue'} />
                    <span className='ml-3'>saving your information...</span>
                </div>
            )
        }
        return null;
    }

    const handleConfirmMeeting = () => {
        triggerWorkflow();
        const payload: CreateCandidateMeetingRequest = {
            jobId: jobId!,
            candidateId: selectedCandidateForBookMeetingProcess?.candidateId!,
            expertId: selectedExpert?.expert?.expertId!,
            trackId: trackId!,
            meetingReference: uniqueCandidateName.current!,
            meetingPurpose: selectedProduct?.displayName!,
            resumeUrl: resumeUrl!,
            candidateNotes: candidateNotes!,
            serviceType: 'MOCKUP_INTERVIEW',
            focusedModules: selectedQuestions!,
            loginExpertId: expert.expertId!
        }
        handleMeetingBooked(payload);
    }

    const getFooter = () => {
        if (currStep === CreateCandidateWorkflowSteps.SELECT_RESUME ||
            currStep === CreateCandidateWorkflowSteps.SELECT_QUESTIONS) {
            return (<div>
                <Button type="button" variant="secondary" className="mx-2" onClick={triggerWorkflowRev}>{'Back'}</Button>
                <Button type="button" onClick={triggerWorkflow}>{'Continue'}</Button>
            </div>)
        }
        return null
    }

    return (
        <ModalComponent
            handleClose={() => setShowCreateCandidate(false)}
            show={true}
            showCloseIcon={true}
            size="lg"
            footer={getFooter()}
            isStatic={true}
        >
            
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            {getSectionToShow()}
        </ModalComponent>
    )
}