import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { ProfileIcon } from "assets";
import React, { useState } from "react";
import { MoreVerticalOutline } from "styled-icons/evaicons-outline";
import { getOnlyDateTimeInLocalFormat } from "utilities/commonUtils";
import { useHistory } from "react-router";
import { Position } from "@react-pdf-viewer/core";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "8px",
  outline: "none",
  border: "none",
};

const buttonStyle = {
  marginTop: "20px",
  display: "block",
  backgroundColor: "#007FFF",
  color: "#fff",
};

const AddLead = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("LinkedIn Search");
  const [listName, setListName] = React.useState("");
  const [searchUrl, setSearchUrl] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setListName("");
    setSearchUrl("");
  };

  const closeIconStyle = {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: "pointer",
    color: "#787878",
    fontSize: "16px",
  };
  const handleAddLeads = () => {
    if (!listName) {
      alert("Please provide a name for the list.");
      return;
    }
    if (selectedOption === "LinkedIn Search" && !searchUrl) {
      alert("Please provide a LinkedIn search URL.");
      return;
    }
    handleClose();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log("File selected:", file);
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      console.log("File dropped:", file);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" onClick={handleOpen} style={buttonStyle}>
          Add Leads
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography
              id="simple-modal-title"
              variant="h6"
              component="h2"
              style={{
                marginBottom: "16px",
                color: "#202020",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Create a list of leads below
            </Typography>
            <hr />

            {/* Radio Group for Switching Options */}
            <RadioGroup
              row
              aria-label="add-leads-method"
              name="add-leads-method"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{ marginBottom: "16px" }}
            >
              <FormControlLabel
                value="LinkedIn Search"
                control={<Radio />}
                label="LinkedIn Search"
              />
              <FormControlLabel
                value="Paste profile URLs"
                control={<Radio />}
                label="Paste Profile URLs"
              />
              <FormControlLabel
                value="Upload CSV file"
                control={<Radio />}
                label="Upload CSV File"
              />
            </RadioGroup>

            {/* Conditional Rendering for LinkedIn Search */}
            {selectedOption === "LinkedIn Search" && (
              <TextField
                fullWidth
                label="Insert LinkedIn Search URL"
                variant="outlined"
                placeholder="https://www.linkedin.com/search/..."
                style={{ marginBottom: "16px" }}
              />
            )}

            {/* Conditional Rendering for Paste Profile URLs */}
            {selectedOption === "Paste profile URLs" && (
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Insert the LinkedIn profile URLs into this field"
                placeholder="https://www.linkedin.com/in/john-doe/ https://www.linkedin.com/in/jane-doe/"
                variant="outlined"
                style={{ marginBottom: "16px" }}
              />
            )}

            {/* Conditional Rendering for Upload CSV File */}
            {selectedOption === "Upload CSV file" && (
              <Box
                sx={{
                  border: "1px dashed #6a0dad",
                  borderRadius: "8px",
                  padding: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileUpload"
                  onChange={handleFileUpload}
                />
                <Typography
                  onClick={() => document.getElementById("fileUpload")?.click()}
                >
                  Drag and drop, or{" "}
                  <span style={{ color: "#6a0dad", cursor: "pointer" }}>
                    browse
                  </span>{" "}
                  your CSV file
                </Typography>
                <Typography>(max links count: 20,000)</Typography>
              </Box>
            )}

            {/* Buttons Section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: "16px",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ color: "#007FFF", borderColor: "#007FFF" }}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#007FFF", color: "#fff" }}
                onClick={() => alert("Save List functionality goes here!")}
              >
                Create a List
              </Button>
            </Box>

            {/* Close Modal Icon */}
            <Typography sx={closeIconStyle} onClick={handleClose}>
              &#10006;
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default AddLead;
