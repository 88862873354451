import React from 'react';
import styled from 'styled-components';
import Education from './Education';
import Experience from './Experience';
import Header from './Header';
import TechnicalSkills from './TechnicalSkills';

interface ResumeContentProps {
    candidateDetails: any;
}

const ResumeContent = ({ candidateDetails }: ResumeContentProps) => {
    const {
        name,
        expectedSalary,
        jobType,
        location,
        email,
        linkedInUrl,
        phoneNumber,
        careerProgress,
        candidateExperience,
        educationDetails,
        skills
    } = candidateDetails;

    const transformedProps = {
        name,
        title: candidateDetails.currentJob?.jobTitle || 'N/A',
        salary: expectedSalary > 0 ? `${candidateDetails.currency} ${expectedSalary}` : 'N/A',
        employmentType: jobType || 'N/A',
        location: location || 'N/A',
        email: email || 'N/A',
        linkedin: linkedInUrl || 'N/A',
        phone: phoneNumber || 'N/A',
        summary: careerProgress?.[0]?.progressDescription || 'No summary available.',
        experiences: candidateExperience?.map((exp: any) => ({
            company: exp.company || 'N/A',
            position: exp.title || 'N/A',
            duration: exp.duration || 'N/A',
            responsibilities: exp.experienceSummaryList ? exp.experienceSummaryList : exp.experienceSummary ? [exp.experienceSummary] : ['N/A'],
        })) || [],
        education: educationDetails?.map((edu: any) => ({
            degree: edu.degree || 'N/A',
            university: edu.university || 'N/A',
            graduationDate: edu.passingYear || 'N/A',
        })) || [],
        certifications: ['Certified ScrumMaster (CSM)', 'AWS Certified Solutions Architect'],
        skills: skills?.map((skill: any) => ({
            name: skill.skillName,
            score: skill.score,
            maxScore: skill.maxScore,
        })) || [],
    };

    return (
        <ResumeWrapper>
            <Header
                name={transformedProps.name}
                title={transformedProps.title}
                salary={transformedProps.salary}
                employmentType={transformedProps.employmentType}
                location={transformedProps.location}
                email={transformedProps.email}
                linkedin={transformedProps.linkedin}
                phone={transformedProps.phone}
                AIResumeUrl={""} 
            />
            <Summary>{transformedProps.summary}</Summary>
            <Divider />
            <ContentSection>
                <LeftColumn>
                    <ExperienceSection>
                        <Experience experiences={transformedProps.experiences} />
                    </ExperienceSection>
                    <EducationSection>
                        <Education education={transformedProps.education} certifications={transformedProps.certifications} />
                    </EducationSection>
                </LeftColumn>
                <RightColumn>
                    <TechnicalSkills skills={transformedProps.skills} />
                </RightColumn>
            </ContentSection>
        </ResumeWrapper>
    );
};

const ResumeWrapper = styled.div`
  max-width: 800px;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
`;

const Summary = styled.p`
  color: #6d6d6d;
  letter-spacing: 0.25px;
  margin-top: 1rem;
`;

const Divider = styled.hr`
  margin: 0;
  width: 100%;
  border: 1px solid #bfd0ff;
`;

const ContentSection = styled.section`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  align-items: start;
`;

const LeftColumn = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RightColumn = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const ExperienceSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EducationSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default ResumeContent;