import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs, Link} from "@mui/material";
import { ProfileIcon } from "assets";
import React, { useState, useEffect } from "react";
import { MoreVerticalOutline } from "styled-icons/evaicons-outline";
import { getOnlyDateTimeInLocalFormat } from "utilities/commonUtils";
import styled from "styled-components";
import OuterDiv from "components/Common/OuterDivComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import { post } from "utilities";
import { API_URL_PREFIX, DEFAULT_TOKEN } from "utilities/constants";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { CampaignLead } from "./types";
import { ChevronLeft } from "styled-icons/material";
import LeadList from "./Components/LeadList";
import { useHistory } from "react-router";
import useCampaignData from "./Components/useCampaignData";


const Wrapper = styled.div``;

const ACTION_BUTTON_STYLE = {
  width: "20px",
  cursor: "pointer",
  color: "#325cd5",
};

const FilterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
`;

const CustomTableContainer = styled(TableContainer)`
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;

const ViewDropdown = styled(Select)`
  background-color: #f5f5f5;
  border-radius: 8px;
  font-size: 14px;
`;

const ActionButton = styled(Button)`
  background-color: #325cd5;
  color: #fff;
  text-transform: none;
  padding: 5px 5px;
  font-size: 12px;
  &:hover {
    background-color: #233c91;
  }
`;

const HeaderTypography = styled(Typography)`
  font-size: 12px;
  color: #616161;
  font-weight: 600;
`;

const BodyTypography = styled(Typography)`
  font-size: 14px;
  color: #212121;
  font-weight: 700;
`;

interface Lead {
  id: number;
  firstName: string;
  lastName : string;
  updatedAt: string;
  occupation: string;
  location: string;
  email: string | null;
}

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

export const LeadListDetails: React.FC = () => {
  const { isMenuVisible } = useMenuVisibility()!;

  const [leadsData, setLeadsData] = useState<Lead[]>([]);
  const [leadId, setLeadId] = useState<number>();
  const [jobId, setJobId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory()
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const { combinedLeadsData, campaignData, loading } = useCampaignData(expertId);

  const fetchLeadDetails = async (leadId: string) => {
    try {
      const payload = {
        token: DEFAULT_TOKEN,
        leadId,
      };
  
      const response = await post(`${API_URL_PREFIX}/campaign/getLeadDetails`, payload);

      const leads =
        response?.output?.leadDetails && Array.isArray(response.output.leadDetails)
          ? response.output.leadDetails
          : [];
  
      if (leads.length === 0) {
        console.warn("No lead details found for this ID:", leadId);
        setError("No lead details available for this ID.");
      } else {
        setLeadsData(leads);
      }
    } catch (err) {
      console.error("Failed to fetch lead details:", err);
      setError("Unable to fetch lead details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const leadId = queryParams.get("id");
    const jobID = queryParams.get("jobId") || "";
    setLeadId(Number(leadId));
    setJobId(jobID);
  
    if (!leadId || !jobID) {
      setError("Invalid Access. No lead ID found.");
      setIsLoading(false);
      return;
    }
    fetchLeadDetails(leadId);
  }, []);

  const Back = ()=>{
    history.goBack()
  }

  if (loading) {
    return (
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
          <Typography>Loading...</Typography>
        </Wrapper>
      </OuterDiv>
    );
  }

  if (!campaignData || !combinedLeadsData) {
    return (
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
          <Typography>No Data Available</Typography>
        </Wrapper>
      </OuterDiv>
    );
  }

  return (
    <>
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
        <Box
          sx={{
            padding: "2px 0px 0px 0px",
            boxShadow: "4px 4px 10px 0px #17171714",
            bgColor: "#000",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ fontSize: "16px", color: "#6d6d6d" }}
          >
            <BackButton
              className="text-primary pr-3"
              onClick={Back}
            >
              <ChevronLeft />
              <span>Back</span>
               
            </BackButton>
            <Typography color="text.primary" style={{ fontWeight: "bold" }}>
              {campaignData && campaignData[0]?.campaignName}
            </Typography>
          </Breadcrumbs>
        </Box>

        <Box sx={{ padding: "10px" }}>
          {combinedLeadsData && combinedLeadsData.length > 0 ? (
            combinedLeadsData.map((leadObj: CampaignLead) => (
              leadObj.id === leadId ? (
                <LeadList key={leadObj.id} lead={leadObj} jobId={jobId} />
              ) : null
              ))
          ) : (
            <Typography>No leads available</Typography>
          )}
        </Box>
          <Box sx={{ padding: "10px" }}>
            {/* Table with Loading/Error/Data Handling */}
            <Box
              sx={{
                boxShadow: "0px 0px 5px grey",
                borderRadius: "8px",
                overflow: "hidden",
                width: "98%",
                margin: "20px auto 5px auto",
              }}
            >
              <FilterContainer>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    background: "#D3D3D3",
                    padding: "3px 5px",
                    borderRadius: "6px",
                  }}
                >
                  All Leads
                  <ViewDropdown size="small" defaultValue={10} sx={{ ml: 1 }}>
                    <MenuItem value={10}>All Leads</MenuItem>
                    <MenuItem value={25}>Getting Ready</MenuItem>
                    <MenuItem value={50}>Invites Sent</MenuItem>
                    <MenuItem value={100}>Invites Sent by Email</MenuItem>
                    <MenuItem value={150}>Accepted Invites</MenuItem>
                    <MenuItem value={200}>Emails Sent</MenuItem>
                    <MenuItem value={250}>Pending Invites</MenuItem>
                    <MenuItem value={300}>Messaged</MenuItem>
                    <MenuItem value={350}>Message Viewed</MenuItem>
                    <MenuItem value={400}>Replied</MenuItem>
                    <MenuItem value={450}>Skills Endorsed</MenuItem>
                    <MenuItem value={500}>Profile Viewed</MenuItem>
                    <MenuItem value={550}>Followed</MenuItem>
                    <MenuItem value={600}>Liked</MenuItem>
                    <MenuItem value={650}>Email Available</MenuItem>
                    <MenuItem value={700}>Withdrawn</MenuItem>
                    <MenuItem value={750}>InMail Messages Sent</MenuItem>
                    <MenuItem value={800}>Paused</MenuItem>
                    <MenuItem value={850}>Whitelisted</MenuItem>
                    <MenuItem value={900}>Blacklisted</MenuItem>
                    <MenuItem value={950}>Failed</MenuItem>
                  </ViewDropdown>

                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  sx={{
                    flexGrow: 1,
                    maxWidth: 300,
                  }}
                />
              </FilterContainer>

              {isLoading ? (
                <Typography align="center" sx={{ margin: "20px 0" }}>
                  Loading leads...
                </Typography>
              ) : error ? (
                <Typography align="center" sx={{ margin: "20px 0", color: "red" }}>
                  {error}
                </Typography>
              ) : leadsData.length === 0 ? (
                <Typography align="center" sx={{ margin: "20px 0" }}>
                  No leads found.
                </Typography>
              ) : (
                <CustomTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <HeaderTypography>#</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Name</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Headline</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Email</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Location</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Last Action</HeaderTypography>
                        </TableCell>
                        <TableCell>
                          <HeaderTypography>Added to CRM</HeaderTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {leadsData && leadsData.map((lead, index) => (
                      <TableRow key={lead.id} style={{ borderBottom: "1px solid #ddd" }}>
                        <TableCell>
                          <BodyTypography> {index + 1} </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ fontWeight: "bold", color: "#333" }}>
                            {lead.firstName} {lead.lastName}
                          </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ color: "#555" }} title={lead.occupation}>
                            {lead.occupation && lead.occupation.length > 40
                              ? `${lead.occupation.slice(0, 40)}...`
                              : lead.occupation}
                          </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ color: lead.email ? "#007BFF" : "#888" }}>
                            {lead.email || "No Email Provided"}
                          </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ color: "#666" }}>
                            {lead.location}
                          </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ color: "#999" }}>
                            Invitation Sent
                          </BodyTypography>
                        </TableCell>
                        <TableCell>
                          <BodyTypography style={{ color: "#999" }}>
                          {new Date(lead.updatedAt).toLocaleDateString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                          })}
                          </BodyTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  </Table>
                </CustomTableContainer>
              )}
            </Box>
          </Box>
        </Wrapper>
      </OuterDiv>
    </>
  );
};
