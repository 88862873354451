import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Tabs, Tab, TextField,FormControlLabel,Checkbox } from "@mui/material";
import styled from "styled-components";
import OuterDiv from "components/Common/OuterDivComponent";
import StatCard from "./Components/StatCard";
import LeadList from "./Components/LeadList";
import AddLead from "./Components/AddLead";
import CampaignCard from "./Components/CampaignCard";
import CustomTabPanel from "./Components/CustomTabPanel";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import { RootState } from "store";
import { Campaign, CampaignLead } from "./types";
import { ChevronLeft } from "styled-icons/material";
import { Button } from "react-bootstrap";
import { BlackListedIcon, CheckmarkIcon, FailedIcon, PausedIcon, ProfileIcon, ProgressIcon, WaitingIcon } from "assets";
import useCampaignData from "./Components/useCampaignData";

const Wrapper = styled.div``;

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

const Savebutton = styled(Button)`
color: #fff;
background: #007FFF;
padding: 5px 25px;
border-radius: 6px;
outline: none;
border: none;
margin-top: 10px;
`

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CampaignDetailsPage: React.FC = () => {
  const [value, setValue] = useState(0);
  const history = useHistory();
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const { campaignData, analyticsData, combinedLeadsData, loading, error } = useCampaignData(expertId);
  const { isMenuVisible } = useMenuVisibility()!;
  const [jobId, setJobId] = useState<string>("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setJobId(queryParams.get("jobId") || "");
  }, []);

  const onBackClick = () => {
    history.goBack();
  }

  const Breadcrumb = () => {
    return (
      <Box
          sx={{
            padding: "2px 0px 0px 0px",
            boxShadow: "4px 4px 10px 0px #17171714",
            bgColor: "#000",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ fontSize: "16px", color: "#6d6d6d" }}
          >
            <BackButton
              variant="link"
              className="text-primary pr-3"
              onClick={onBackClick}
            >
              <ChevronLeft />
              <span>Back</span>
            </BackButton>
            {/* <Link underline="hover" color="inherit" href="#">
              Sr. Principal Software Engineer, Shipping Tech & Services
            </Link> */}
            <Typography color="text.primary" style={{ fontWeight: "bold" }}>
              {campaignData && campaignData[0]?.campaignName}
            </Typography>
          </Breadcrumbs>
      </Box>
    )
  }

  if (loading) {
    return (
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
          <Breadcrumb />
          <Typography>Loading...</Typography>
        </Wrapper>
      </OuterDiv>
    );
  }

  if (error) {
    return (
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
          <Breadcrumb />
          <Typography>Error: {error}</Typography>
        </Wrapper>
      </OuterDiv>
    );
  }

  if (!campaignData) {
    return (
      <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
        <Wrapper>
          <Breadcrumb />
          <Typography>No Data Available</Typography>
        </Wrapper>
      </OuterDiv>
    );
  }

  const combinedData: Campaign[] = campaignData.map((campaign: Campaign) => {
    const analytics = analyticsData.campaignAnalytics;
    return {
      ...campaign,
      analytics:
        analytics.campaign_id === campaign.campaignId ? analytics : null,
    };
  });

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
      <Wrapper>
        <Breadcrumb />

        {combinedData.map((singleCampaign: Campaign) => (
          <Box key={singleCampaign.campaignId}>
            <Box
              sx={{
                padding: "16px",
                margin: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                bgcolor: "#fff",
              }}
            >
              <CampaignCard
                singleCampaign={singleCampaign}
                expertId={expertId}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(21, 1fr)",
                  gap: 2,
                  marginTop: "15px",
                }}
              >
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="All Leads"
                    value={singleCampaign.analytics?.total_leads ?? 0}
                    Icon={ProfileIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="In Progress"
                    value={singleCampaign.analytics?.in_progress_leads ?? 0}
                    Icon={ProgressIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Completed All steps"
                    value={singleCampaign.analytics?.finished_leads ?? 0}
                    Icon={CheckmarkIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Awaiting"
                    value={singleCampaign.analytics?.waiting_leads ?? 0}
                    Icon={WaitingIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Paused"
                    value={singleCampaign.analytics?.paused_leads ?? 0}
                    Icon={PausedIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Failed"
                    value={singleCampaign.analytics?.failed_leads ?? 0}
                    Icon={FailedIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Blacklisted"
                    value={singleCampaign.analytics?.blacklisted_leads ?? 0}
                    Icon={BlackListedIcon}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                bgcolor: "#fff",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Audience" {...a11yProps(0)} />
                  <Tab label="Sequence" {...a11yProps(1)} />
                  <Tab label="Statistics" {...a11yProps(2)} />
                  <Tab label="Settings" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}> 
                  <Typography variant="h6">Lists of leads</Typography>
                  <AddLead />
                </Box>
                {combinedLeadsData.length > 0 ? (
                  combinedLeadsData.map((leadObj: CampaignLead) => (
                    <LeadList key={leadObj.id} lead={leadObj} jobId={jobId} />
                  ))
                ) : (
                  <Typography>No leads available</Typography>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Coming soon...
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Coming soon...
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
              <Box sx={{ p: 1 }}>
              <Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: 4, padding: 2, borderBottom: '1px solid #ccc' }}>
              <Box sx={{ width: '50%' }}>
                <TextField
                  label="Campaign Name"
                  defaultValue="Abc2"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Webhooks Integration"
                  defaultValue="Choose when to push data"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                <Typography sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Do not add a lead to the campaign if:
                </Typography>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Same leads found in other campaigns (recommended)"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Does not have an open profile"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="No photo on lead's profile"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Less than 500 connections"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Has a free LinkedIn account"
                />
              </Box>
            </Box>
          </Box>
          <Savebutton 
           
            >
            Save
          </Savebutton>
        </Box>
              </CustomTabPanel>
            </Box>
          </Box>
        ))}
      </Wrapper>
    </OuterDiv>
      );
  };