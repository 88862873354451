import { Microphone } from "@styled-icons/boxicons-regular";
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp";
import { CircleWithCross } from '@styled-icons/entypo/CircleWithCross';
import { WindowMinimize } from "@styled-icons/fa-regular/WindowMinimize";
import { FileAudio } from "@styled-icons/fa-solid";
import { SettingsVoice } from "@styled-icons/material-sharp/SettingsVoice";
import AudioRecorder from 'components/AudioRecorder';
import { AppLink } from 'components/Common/AppLink';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import { IconContainer } from 'components/Common/IconContainer/IconContainer';
import { QueryTabs } from 'components/Expert/Feedback/QueryTabs';
import { ModalComponent } from 'components/Modals/Modal';
import AnswerWrapper from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/AnswerWrapper';
import { CandidateAttachments } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/CandidateAttachments';
import { CandidateRecordings } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/CandidateRecordings';
import { FeedbackComponent } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/Feedback';
import FraudCandidateReason from "containers/Feedback/TabContent/Submissions/SubmissionDetail/FraudCandidateReason";
import { AnswerContainer, EvaluatedCapabilityWithActive, EvalWithActive, FeedbackWithActive, QuestionDetail } from "containers/Feedback/TabContent/Submissions/SubmissionDetail/ISubmissionDetail";
import { QuestionHints } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/QuestionHints';
import { SampleSolutions } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/SampleSolutions';
import { FlexBox, LinkWrapper, QuestionContainer, QuestionLinks, SubmissionHeader } from 'containers/Feedback/TabContent/Submissions/SubmissionDetail/SubmissionDetail.styles';
import { QuestionDiv, SmallSpan } from "containers/Feedback/TabContent/Submissions/Submissions.styles";
import { IQuestionCapability } from "containers/Meeting/meetingTypes";
import { useLoader } from 'context/loaderContext';
import React, { useEffect, useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { meetingService } from 'services/meeting';
import { submissionService } from 'services/submission';
import { RootState } from 'store';
import styled from 'styled-components';
import { RightArrow, Save, LeftArrow } from "styled-icons/boxicons-solid";
import { Attachment } from 'styled-icons/entypo';
import { getSketchDataCopyFromArray } from 'utilities';
import { getQuillContent } from "utilities/commonUtils";
import { EXPERT_EVAL_METRICS_FEEDBACK, FEEDBACK_TYPES } from 'utilities/constants';
import { DefaultToastSettings } from "utilities/defaults";
import { useMessagePopup } from 'context/messagePopContext';


export const EllipsedSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  p{
    margin-bottom: 0px !important;
  }
`;

interface IMeetingEvaluation {
    trackId: string;
    capabilityId: string;
    candidateId: string;
    questionId: string;
    meetingDetailId: string;
    serviceType: string;
    startRecording: boolean;
    onCollapse: Function;
    readonly?: boolean;
    evaluated?: boolean;
    isFirstQuestion: boolean;
    isLastQuestion: boolean;
    openNextQuestion: () => void;
    openPrevQuestion: () => void;
    onSave: () => void;
}


export const MeetingEvaluation = ({ trackId,
    evaluated,
    capabilityId,
    questionId,
    candidateId,
    meetingDetailId,
    serviceType,
    startRecording,
    onCollapse,
    readonly,
    onSave,
    openNextQuestion, 
    openPrevQuestion, 
    isFirstQuestion, 
    isLastQuestion }: IMeetingEvaluation) => {
    const Loader = useLoader();
    const message = useMessagePopup();
    const expert = useSelector((state: RootState) => state.auth.user);
    const [detailedSubmission, setDetailedSubmission] = useState<{ question: QuestionDetail, answers: AnswerContainer[] }>();
    const [activeSubmission, setActiveSubmission] = useState<number>(0);
    const [showRecordingModal, setShowRecordingModal] = useState<boolean>(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [showQuestionHintsModal, setShowQuestionHintsModal] = useState<boolean>(false);
    const [showSampleSolutionsModal, setShowSampleSolutionsModal] = useState<boolean>(false);
    const [questionSampleSolutions, setQuestionSampleSolutions] = useState<any>();
    const [questionHints, setQuestionHints] = useState<any>();
    const [isVoiceRecorderOpen, setIsVoiceRecorderOpen] = useState<boolean>(startRecording);
    const [recordingDirectory, setRecordingDirectory] = useState<string>();
    const [isRecordingMinimized, setIsRecordingMinimized] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showFraudCandidateModal, setShowFraudCandidateModal] = useState<boolean>(false);
    const [queryTabs, setQueryTabs] = useState<any>([]);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const selectedDivRef = React.useRef(null)
    const [evaluationComplete, setEvaluationComplete] = useState<boolean>(false);

    const getMeetingEvaluations = async () => {
        Loader.showLoader();
        const questionCapability: IQuestionCapability = {
            questionId,
            capabilityId
        }
        let input = {
            trackId,
            evaluated,
            expertId: expert?.expertId,
            candidateId,
            questionCapabilities: [questionCapability],
            meetingDetailId
        };
        const result = await meetingService.getMeetingEvaluations(input);
        let { answers } = result.output[0];
        // Keeping last element in front if its an empty answer
        if (answers?.length > 0 && Object.keys(answers[answers?.length - 1].answer).length === 0) {
            answers.unshift(answers.pop()!);
        }
        // Removing elements whose feedback array is empty
        result.output[0].answers = answers.filter((val: any) => val?.feedbacks?.length !== 0);
        handleSetDetailedSubmission(result.output[0])
        setInitialDataLoaded(true);
        if (selectedDivRef.current) {
            (selectedDivRef.current as any).scrollIntoView({ behavior: 'smooth' })
        }
        Loader.hideLoader();
    }

    const handleShowQuestionHintsModal = async (value: boolean, questionId: string) => {
        if (detailedSubmission) {
            setShowQuestionHintsModal(value);
            const result = await submissionService.getHints({ questionId, candidateTrackId: detailedSubmission.answers[activeSubmission].answer?.candidateTrackId, expertId: expert?.expertId });
            setQuestionHints(result.payload);
        }
    }

    const handleShowSampleSolutionsModal = async (value: boolean, questionId: string) => {
        if (detailedSubmission) {
            setShowSampleSolutionsModal(value);
            const result = await submissionService.getSampleSolutions({ questionId, candidateTrackId: detailedSubmission.answers[activeSubmission].answer?.candidateTrackId, expertId: expert?.expertId });
            setQuestionSampleSolutions(result.payload);
        }
    }

    const handleLastThreeTabs = (answers: AnswerContainer[]) => {
        let selectedanswer = answers;
        if (answers?.length > 3) {
            let selectedarr = selectedanswer?.filter((a: AnswerContainer, inx: number) => inx < 3);
            let selectedarrwithparentindex: any[] = [];
            answers.forEach((answer: AnswerContainer, pindex: number) => {
                if (selectedarr.some((ans: AnswerContainer) => ans.answer._id === answer.answer._id)) {
                    selectedarrwithparentindex.push(pindex);
                }
            })
            return selectedarrwithparentindex;
        }
        else {
            return selectedanswer?.map((answer: AnswerContainer) => {
                let parentinx = answers.findIndex((ans: AnswerContainer) => ans.answer._id === answer.answer._id);
                return parentinx;
            });
        }
    }

    const handleSaveFeedback = async (fraudDTO?: { fraud: boolean, fraudReason: string }) => {
        if (detailedSubmission) {
            const input: any = {
                meetingDetailId,
                candidateId,
                expertId: expert?.expertId,
                trackId,
                candidateTrackId: detailedSubmission.answers[activeSubmission].feedbacks[0].candidateTrackId,
                serviceType,
                structureFeedback: {
                    ...detailedSubmission.answers[activeSubmission].feedbacks[0],
                    questionAnswerId: detailedSubmission.answers[activeSubmission].answer._id,
                    questionId: detailedSubmission.question._id,
                    questionTitle: detailedSubmission.question.title,
                    capabilityId,
                    containRecording: detailedSubmission.answers[activeSubmission].answer.containRecording,
                    feedbackId: detailedSubmission.answers[activeSubmission].feedbacks[0]._id
                },
            };
            if (fraudDTO) {
                input['fraudInfo'] = { ...fraudDTO }
            }
            try {
                const result = await meetingService.saveMeetingStructureFeedback(input);
                onSave();
                openNextQuestion();
                const inx = result.output.structureFeedbacks.findIndex((f: any) => f.questionId === detailedSubmission.question._id);
                if (inx !== -1) {
                    detailedSubmission.answers[activeSubmission].feedbacks[0]._id = result.output.structureFeedbacks[inx].feedbackId;
                    handleSetDetailedSubmission({ ...detailedSubmission });
                }
                toast.success("Feedback saved successfully", DefaultToastSettings);
            } catch (error) {
                toast.error("Error saving feedback...", DefaultToastSettings);
            }
        }
    }

    const handleSaveFeedbackClick = async () => {
        setIsSaving(true);
        await handleSaveFeedback();
        setIsSaving(false);
    }

    const markCandidateFraud = async (fraudDTO: { fraud: boolean, fraudReason: string }) => {
        await handleSaveFeedback(fraudDTO);
        setShowFraudCandidateModal(false);
        toast.warning("Candidate was marked as fraud.", DefaultToastSettings);
    }

    const handleSliderChange = (answerInx: number, feedbackInx: number, value: number, capabilityId: string, evalId: string) => {
        if (detailedSubmission) {
            detailedSubmission.answers[activeSubmission].feedbacks[feedbackInx].evaluatedCapabilities.map(cap => {
                if (cap.capabilityId === capabilityId) {
                    cap.evals.map(evaluation => {
                        if (evaluation.evalId === evalId) {
                            evaluation.evalMetricsFeedbackValue = value;
                            evaluation.evalMetricsFeedback = EXPERT_EVAL_METRICS_FEEDBACK.EVAL(value);
                        }
                        return evaluation;
                    })
                }
                return cap;
            });
            handleSetDetailedSubmission({ ...detailedSubmission });
        }
    }

    const onExpandEval = (capabilityId: string, evalId: string, isActive: boolean, answerInx: number, feedbackInx: number) => {
        if (detailedSubmission) {
            detailedSubmission.answers[activeSubmission].feedbacks[feedbackInx].evaluatedCapabilities.map(cap => {
                if (cap.capabilityId === capabilityId) {
                    cap.evals.map(evaluation => {
                        if (evaluation.evalId === evalId) {
                            evaluation.isActive = isActive;
                        }
                        return evaluation;
                    })
                }
                return cap;
            });
            handleSetDetailedSubmission({ ...detailedSubmission });
        }
    }

    const handleCommentChange = (answerInx: number, feedbackInx: number, value: string, capabilityId: string, evalId: string) => {
        if (detailedSubmission) {
            detailedSubmission.answers[activeSubmission].feedbacks[feedbackInx].evaluatedCapabilities.map(cap => {
                if (cap.capabilityId === capabilityId) {
                    cap.evals.map(evaluation => {
                        if (evaluation.evalId === evalId) {
                            evaluation.evalTextFeedback = value;
                        }
                        return evaluation;
                    })
                }
                return cap;
            });
            handleSetDetailedSubmission({ ...detailedSubmission });
        }
    }

    const handleFeedbackchange = (answerInx: number, feedbackInx: number, value: string) => {
        if (detailedSubmission) {
            detailedSubmission.answers[activeSubmission].feedbacks[feedbackInx].feedback = value;
            handleSetDetailedSubmission({ ...detailedSubmission });
        }
    }

    const handleVoiceRecorderClose = () => {
        setIsVoiceRecorderOpen(false);
    }

    const handleMinimize = () => {
        setIsRecordingMinimized(true);
        document.body.style.overflow = "auto";
    }

    const handleAddVoiceRecording = (cantainRecording?: boolean) => {
        let richText = detailedSubmission?.answers[activeSubmission].answer.answer;
        const recordingText = "[This answer contains recordings]\n";
        const noRecordingText = "[No Recordings]\n";
        let containRecording = false;
        if ((!richText || richText?.indexOf(recordingText) === -1) && cantainRecording) {
            richText = `${recordingText}` + richText;
            containRecording = true;
        }
        else {
            richText = richText?.replace(recordingText, noRecordingText) || "";
            containRecording = false;
        }
        if (detailedSubmission) {
            detailedSubmission.answers[activeSubmission].answer.answer = richText;
            detailedSubmission.answers[activeSubmission].answer.containRecording = containRecording;
            handleSetDetailedSubmission({ ...detailedSubmission });
        }
    }

    const handleSetDetailedSubmission = (detailedSubmission: { question: QuestionDetail, answers: AnswerContainer[] }) => {
        setDetailedSubmission({ ...detailedSubmission })
    }

    const onNext = () => {
        if (evaluationComplete){
            message.confirm("Would you like to proceed without saving?", (response: any) => { openNextQuestion();})
        } else {
            openNextQuestion();
        }
    }

    const onPrev = () => {
        if (evaluationComplete){
            message.confirm("Would you like to proceed without saving?", (response: any) => { openPrevQuestion();})
        } else {
            openPrevQuestion();
        }
    }

    const handleVoiceRecorderSubmit = (cantainRecording?: boolean) => {
        setIsVoiceRecorderOpen(false);
        handleAddVoiceRecording(cantainRecording)
    }

    const historySketchData = detailedSubmission && getSketchDataCopyFromArray(detailedSubmission.answers);

    const getRecordingDir = (selectedVersion?: number): string => {
        selectedVersion = selectedVersion ? selectedVersion : detailedSubmission?.answers?.length || 1;
        const dir = candidateId + "/" + capabilityId + "/" + questionId + "/recordings/v" + selectedVersion;
        return dir;
    }

    useEffect(() => {
        getMeetingEvaluations();
    }, [])

    useEffect(() => {
        setRecordingDirectory(getRecordingDir());
        if (detailedSubmission) {
            const tabs = handleLastThreeTabs(detailedSubmission?.answers.reverse() || [])?.map((qindex: number) => { return { name: "" + ((detailedSubmission?.answers?.length || 0) - qindex), identifier: (detailedSubmission?.answers?.length || 0) - qindex - 1 }; });
            setQueryTabs(tabs);
            if (tabs.length) {
                setActiveSubmission(tabs[0].identifier);
            }
        }
    }, [initialDataLoaded])

    useEffect(() => {
        if (detailedSubmission) {
            const currentFeedBack = detailedSubmission.answers[activeSubmission].feedbacks?.[0];
            const containsFeedback = !!getQuillContent(currentFeedBack.feedback);
            const evalsWithFeedback = currentFeedBack.evaluatedCapabilities.filter(evals => {
                const evalsWithVals = evals.evals.filter((evaluation: EvalWithActive) => {
                    return evaluation.evalMetricsFeedbackValue >= 0 && evaluation.evalMetricsFeedbackValue !== null && evaluation.evalMetricsFeedbackValue !== undefined;
                })
                return evalsWithVals.length === evals.evals.length;
            });
            setEvaluationComplete(containsFeedback && evalsWithFeedback.length === currentFeedBack.evaluatedCapabilities.length)
        }
    }, [detailedSubmission])

    return (
        detailedSubmission ? <>
            <div ref={selectedDivRef} className="d-flex align-items-center justify-content-end">
                <Button type="button" className="btn-sm btn-success d-flex ml-2" onClick={() => {
                    isRecordingMinimized ? setIsRecordingMinimized(false) : setIsVoiceRecorderOpen(true);
                    setRecordingDirectory(getRecordingDir());
                }}><IconContainer tooltip="View/Record Answer" color="white" icon={Microphone} />{readonly ? "View Recordings" : (isRecordingMinimized ? 'Maximize Recording Window' : 'Record')}</Button>
                <Button type="button" className="btn-sm btn-info d-flex ml-2" onClick={() => onCollapse()}><IconContainer tooltip="Collapse" color="white" icon={ChevronUp} />{'Collapse'}</Button>
            </div>
            <QuestionContainer id={"submissionDetail"}>
                <QuestionDiv > {'Q) ' + detailedSubmission.question.title}</QuestionDiv >
                <RichTextEditor
                    value={detailedSubmission.question.description}
                    disabled={true}
                    id={detailedSubmission.question._id}
                    placeholder='Question should appear here...'
                />
                <QuestionLinks>
                    <LinkWrapper>
                        {!!detailedSubmission.question?.hintIds?.length && !!detailedSubmission.answers[activeSubmission].answer?.candidateTrackId && <AppLink onClick={() => handleShowQuestionHintsModal(true, detailedSubmission.question._id)} variant="link">{'Question Hints'}</AppLink>}
                        {!!detailedSubmission.question?.solutionIds?.length && !!detailedSubmission.answers[activeSubmission].answer?.candidateTrackId && <AppLink onClick={() => handleShowSampleSolutionsModal(true, detailedSubmission.question._id)} variant="link">{'Sample Solutions'}</AppLink>}
                    </LinkWrapper>
                </QuestionLinks>
                <FlexBox>
                    {!!queryTabs.length && <QueryTabs
                        precedingText="Submission"
                        tabDetails={queryTabs}
                        activeIndex={activeSubmission}
                        handleClick={(e: any, index: number) => {
                            setActiveSubmission(index);
                            setRecordingDirectory(getRecordingDir(index + 1));
                        }}
                    />}
                    <div className='d-flex'>
                        <IconContainer
                            className="mr-2"
                            icon={SettingsVoice}
                            onClick={() => setIsVoiceRecorderOpen(true)}
                            tooltip="Record Candidate's Answer"
                        />
                        <IconContainer
                            className="mr-2"
                            icon={FileAudio}
                            onClick={() => setShowRecordingModal(true)}
                            tooltip='Candidate Voice Recordings'
                        />
                        <IconContainer
                            icon={Attachment}
                            onClick={() => setShowAttachmentModal(true)}
                            tooltip='Candidate Attachments'
                        />
                    </div>
                </FlexBox>
                {
                    detailedSubmission.answers && Array.from(detailedSubmission.answers).map((answer: any, ansInx: number) => {
                        return (activeSubmission === ansInx ||
                            activeSubmission === -1) &&
                            (activeSubmission === -1 && !ansInx ?
                                null :
                                <div key={answer.answer._id + ansInx}>
                                    {activeSubmission === -1 && <SubmissionHeader key={answer._id} >
                                        {'Submission' + (ansInx + 1)}
                                        <SmallSpan>
                                            <Moment format="MMM DD,YYYY">
                                                {answer.answer.createdAt}
                                            </Moment>
                                        </SmallSpan>
                                    </SubmissionHeader>}
                                    {detailedSubmission.answers[activeSubmission].answer?.candidateTrackId && <AnswerWrapper expertId={expert?.expertId}
                                        answer={answer}
                                        questionDetails={{ id: detailedSubmission.question._id, name: detailedSubmission.question.title, answerType: detailedSubmission.question.answerType, description: detailedSubmission.question.description }}
                                        candidateTrackId={detailedSubmission.answers[activeSubmission].answer?.candidateTrackId}
                                        questionId={detailedSubmission.question._id} />}
                                    {detailedSubmission.answers[activeSubmission].feedbacks?.map((feedback: FeedbackWithActive, fi: number) => {
                                        feedback.isActive = true;
                                        feedback.evaluatedCapabilities.forEach((ec: EvaluatedCapabilityWithActive) => {
                                            ec.isActive = true;
                                            ec.evals.forEach((ev: EvalWithActive) => {
                                                if (ev.evalTextFeedback)
                                                    ev.isActive = true
                                            });
                                        });

                                        return <div>
                                            <FeedbackComponent
                                                handleSliderChange={(val: number, capabilityId: string, evalId: string) => handleSliderChange(ansInx, fi, val, capabilityId, evalId)}
                                                historySketchData={historySketchData}
                                                editable={!feedback.feedbackStatus || ((feedback?.feedbackStatus === FEEDBACK_TYPES.RESPONSE_IS_SUBMITTED_TO_EXPERT ||
                                                    feedback?.feedbackStatus === FEEDBACK_TYPES.EXPERT_REVIEWING_RESPONSE) &&
                                                    feedback?.expertId === expert?.expertId
                                                    && activeSubmission !== -1)}
                                                key={feedback._id + fi}
                                                {...feedback}
                                                questionAnswerId={detailedSubmission.question._id}
                                                onExpandEval={(capabilityId: string, evalId: string, isActive: boolean) => onExpandEval(capabilityId, evalId, isActive, ansInx, fi)}
                                                handleCommentChange={(val: string, capabilityId: string, evalId: string) => handleCommentChange(ansInx, fi, val, capabilityId, evalId)}
                                                handleFeedbackchange={(value: string) => handleFeedbackchange(ansInx, fi, value)}
                                                placeholder={detailedSubmission.answers[activeSubmission].answer.containRecording ? "This answer contains recording" : null}
                                            /></div>
                                    }
                                    )}
                                </div>)
                    }
                    )
                }
                {!readonly &&
                    (!detailedSubmission.answers[activeSubmission].feedbacks[0]?.feedbackStatus ||
                        ((detailedSubmission.answers[activeSubmission].feedbacks[0]?.feedbackStatus === FEEDBACK_TYPES.RESPONSE_IS_SUBMITTED_TO_EXPERT
                            || detailedSubmission.answers[activeSubmission].feedbacks[0]?.feedbackStatus === FEEDBACK_TYPES.EXPERT_REVIEWING_RESPONSE)
                            && activeSubmission !== -1)
                    )
                    && <FlexBox className="flex-row justify-content-between mt-4">
                        <Col className="d-flex align-items-center">
                            <Button
                                disabled={!evaluationComplete || isSaving}
                                type="button"
                                className="d-flex align-items-center btn btn-primary" onClick={() => handleSaveFeedbackClick()}>
                                <IconContainer tooltip="Collapse" color="white" icon={Save} />{isSaving && <Spinner
                                    className="mr-2 mt-2 mb-2"
                                    color={'#000'}
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                {isSaving ? 'Saving...' : 'Save'}</Button>
                            <Button
                                type="button"
                                className="d-flex align-items-center btn btn-danger ml-2"
                                disabled={!evaluationComplete}
                                onClick={() => setShowFraudCandidateModal(true)}>
                                <IconContainer tooltip="Collapse" color="white" icon={CircleWithCross} />
                                {'Mark Fraud'}
                            </Button>
                        </Col>
                        <div className="d-flex align-items-center justify-content">
                            <Col>
                                <Button type="button" className="btn-sm btn-info d-flex ml-2" onClick={() => onCollapse()}>
                                    <IconContainer tooltip="Collapse" color="white" icon={ChevronUp} />{'Collapse'}</Button>
                            </Col>
                            {!isFirstQuestion && <Col>
                                <Button className="float-right d-flex align-items-center btn-primary btn-sm" onClick={onPrev}>
                                    <IconContainer color="white" icon={LeftArrow} />{'Prev'}</Button>
                            </Col> || null}
                            {!isLastQuestion && <Col>
                                <Button className="float-right d-flex align-items-center btn-primary btn-sm" onClick={onNext}>
                                    <IconContainer color="white" icon={RightArrow} />{'Next'}</Button>
                            </Col> || null}                   
                        </div>
                    </FlexBox>}
                <ModalComponent
                    handleClose={() => setShowFraudCandidateModal(false)}
                    show={showFraudCandidateModal}
                    showCloseIcon={true}
                    header={'Fraud Candidate'}
                >
                    <FraudCandidateReason markCandidateFraud={markCandidateFraud} />
                </ModalComponent>
                <ModalComponent
                    handleClose={() => setShowAttachmentModal(false)}
                    show={showAttachmentModal}
                    showCloseIcon={true}
                    header={'Attachments'}
                >
                    {detailedSubmission.question.capabilities[0] && <CandidateAttachments candidateId={candidateId} capabilityId={detailedSubmission.question.capabilities[0].capabilityId} questionId={detailedSubmission.question._id} expertId={expert?.expertId} version={"" + (detailedSubmission.answers?.length || 1)} />}
                </ModalComponent>
                <ModalComponent
                    handleClose={() => setShowRecordingModal(false)}
                    show={showRecordingModal}
                    showCloseIcon={true}
                    header={'Candidate Recordings'}
                >
                    {detailedSubmission.question.capabilities[0] && <CandidateRecordings candidateId={candidateId} capabilityId={detailedSubmission.question.capabilities[0].capabilityId} questionId={detailedSubmission.question._id} version={"" + (detailedSubmission.answers?.length || 1)} expertId={expert?.expertId} />}
                </ModalComponent>
                <ModalComponent
                    handleClose={() => setShowSampleSolutionsModal(false)}
                    show={showSampleSolutionsModal}
                    showCloseIcon={true}
                    header={'Sample Solution'}
                >
                    {questionSampleSolutions ? <SampleSolutions
                        sampleSolutions={questionSampleSolutions} /> : null}
                </ModalComponent>
                <ModalComponent
                    handleClose={() => setShowQuestionHintsModal(false)}
                    show={showQuestionHintsModal}
                    showCloseIcon={true}
                    header={'Question Hints'}
                >
                    {questionHints ? <QuestionHints
                        hints={questionHints} /> : null}
                </ModalComponent>
                <ModalComponent
                    className={isRecordingMinimized ? "minimize-css" : ""}
                    backdropClassName={isRecordingMinimized ? "minimize-css" : ""}
                    isStatic={true}
                    handleClose={handleVoiceRecorderClose}
                    show={isVoiceRecorderOpen}
                    headerComponent={
                        <div className="w-100 h4 d-flex justify-content-center align-items-center flex-column">
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <span>{'Audio/Video Recorder'}</span>
                                <IconContainer tooltip="Minimize" onClick={handleMinimize} icon={WindowMinimize} />
                            </div>
                            <div className="h6 mt-2 text-muted"><strong>{'Tip: '}</strong>{'You may need to optimize your recordings if it exceeds 3 minutes'}</div>
                        </div>}
                >
                    <AudioRecorder readonly={readonly} handleCancel={handleVoiceRecorderClose} handleSubmit={handleVoiceRecorderSubmit} directory={recordingDirectory || ""}
                        expertId={expert?.expertId} />
                </ModalComponent>
            </QuestionContainer ></> : <></>
    )
}
