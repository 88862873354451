import axios from 'axios';
import { ModalComponent } from 'components/Modals/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { candidateService } from 'services/candidate';
import { convertHttpsToHttpFromUrl } from 'utilities/commonUtils';
import ImageCroper from './ImageCroper';

import ImageUpload from './ImageUpload';

type AwsUploaderProps = {
    onUpload: Function;
    url?: string;
    directory: string;
    expertId: string;
    mandateImageCrop?: boolean;
    allowAllUpload?: boolean;
}

export const AwsUploader = ({ onUpload, url: imageurl, directory, expertId, mandateImageCrop, allowAllUpload }: AwsUploaderProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<boolean>(false);
    const [url, setURL] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showCropingModal, setShowCropingModal] = useState<boolean>(false);
    const [file, setFile] = useState<any>();

    const uploadImageToS3 = (bufferArray: any, fileName : any) => {

        const handleActionAfterUpload = (err?: any) => {
            setLoading(false);
            if (err) {
                setError(true);
                alert('Failed with error: ' + err);
                return;
            }
            const location = directory + '/' + fileName;
            setError(false);
            setURL(location);
            onUpload(location);
        }

        setLoading(true);
        const newFileName = file?.name?.trim();
        candidateService.getS3SignedInCredentials({ path: directory + '/' + fileName, expertId })
            .then((res) => {
                let bucketUrl = res.output.url;
                // Uploading to bucket
                axios.put(bucketUrl, bufferArray)
                    .then((res) => {
                        handleActionAfterUpload();
                        setFile(null);
                    }).catch(err => {
                        console.log(err);
                        axios.put(convertHttpsToHttpFromUrl(bucketUrl), bufferArray)
                            .then((res) => {
                                handleActionAfterUpload();
                            }).catch(err => {
                                handleActionAfterUpload(err);
                            })
                    })
            }).catch(err => {
                handleActionAfterUpload(err);
            });
    }

    const handleCropedUpload = (base64Buffer: any, fileName:any) => {
        const bufferArray = Buffer.from(base64Buffer.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        uploadImageToS3(bufferArray,fileName);
    };

    const removeCropingModal = () => {
        setShowCropingModal(false);
    }

    const onCancleCropping = () => {
        if (fileInput.current) {
            fileInput.current['files'] = null;
        }
        setFile(undefined);
        removeCropingModal();
    }

   const handleUnCropedUpload = (selectedFile: File) => {
    const fileName = selectedFile.name; 
    const reader = new FileReader();

    reader.onloadend = () => {
        if (reader.result) {
            try {
                const bufferArray = Buffer.from(reader.result as ArrayBuffer);
                uploadImageToS3(bufferArray, fileName);
            } catch (error) {
                console.error("Error converting file to buffer:", error);
                setLoading(false);
            }
        }
    };

    reader.onerror = (err) => {
        console.error("Error reading file:", err);
        setLoading(false);
    };

    reader.readAsArrayBuffer(selectedFile);
};

    const handleFileSelected = () => {
        setShowCropingModal(true);
    }

    useEffect(() => {
        setURL(imageurl);
    }, [imageurl])

    return (
        <>
            <ImageUpload
                fileInputRef={fileInput}
                url={url}
                error={error}
                isLoading={loading}
                onFileUpload={mandateImageCrop ? () => { } : handleUnCropedUpload}
                expertId={expertId}
                onFileSelect={mandateImageCrop ? handleFileSelected : null}
                file={file}
                setFile={setFile}
                allowAllUpload={allowAllUpload}
            />
            <ModalComponent
                isStatic={true}
                show={showCropingModal}
                showCloseIcon={true}
                header={'Crop Image'}
                handleClose={onCancleCropping}
            >
                <ImageCroper
                    src={file}
                    closeEditor={removeCropingModal}
                    uploadImage={handleCropedUpload} />
            </ModalComponent>
        </>
    )
}
