import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ScrollableMention from "components/Mentions/Mention";
import { useMessagePopup } from "context/messagePopContext";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MentionItem } from "react-mentions";
import { useSelector } from "react-redux";
import Loader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { jobsService } from "services/jobs";
import { RootState } from "store";
import styled from "styled-components";
import { Delete, Shuffle } from "styled-icons/material";
import { CommentObject, Task, TASK_STATUS } from "types/Jobs";
import { getDateTimeInLocalFormat } from "utilities/commonUtils";
import { DefaultToastSettings } from "utilities/defaults";
import * as Yup from "yup";
import {
  StatusToDisplayText,
  TaskStatusToIcon,
  TaskStatusToKeyArray,
} from "../Tasks/TaskDisplayer";
import { PersonSwap } from "styled-icons/fluentui-system-filled";
import { TeamMemberDataType } from "containers/Settings/CreateTeam/Types/types";
import { formatLabel } from "utilities/utils";

type IProps = {
  expertId: string;
  jobId: string;
  candidateId?: string;
  ownerExpert?: {
    fullname: string;
    expertId: string;
    email: string;
  };
};

const ActionToolTipId = "action-tool-tip";

const actionButtonStyle = {
  width: "1.5rem",
  cursor: "pointer",
  marginRight: "0.5rem",
};

const ValidationSchema = Yup.object({
  message: Yup.string().required("Comment is required"),
});

const Main = styled.div`
  width: 90vw;
  max-width: 30rem;
  .previous-comments-container {
    padding: 1rem;
    height: 20rem;
    overflow: auto;
  }
  .previous-comments-container::-webkit-scrollbar {
    width: 10px;
  }
  .previous-comments-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  .previous-comments-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .author-name {
    line-height: 1;
  }
  .user-input-container {
    text-align: right;
    padding: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .scrollable-mention-container {
    max-height: 200px; /* Restrict the height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
`;

const AddCommentModal = (props: IProps) => {
  const message = useMessagePopup();

  const [loading, setLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentObject[]>([]);
  const [mentions, setMentions] = useState<MentionItem[]>([]);
  const { teamMembers } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    fetchAllCandidatesComments();
  }, []);

  const fetchAllCandidatesComments = () => {
    setLoading(true);
    jobsService
      .getCandidateComments({
        expertId: props.expertId,
        jobId: props.jobId,
        candidateId: props.candidateId,
      })
      .then((res) => {
        setComments(res.output);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddCommentSubmit = (
    formData: { message: string },
    setFieldValue: (name: string, value: string) => void
  ) => {
    setLoading(true);
    jobsService
      .addCandidateComment({
        expertId: props.expertId,
        jobId: props.jobId,
        candidateId: props.candidateId,
        message: formData.message,
        taggedTeamMembers: mentions.map((mention) => mention.id),
      })
      .then((res) => {
        setFieldValue("message", "");
        setComments((prev) => [{ ...res.output }, ...prev]);
      })
      .catch((e) => {
        toast.error(
          "Could not add comment please try again.",
          DefaultToastSettings
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteComment = (noteId: string) => {
    message.confirm(
      "You are about to delete this comment. Are you sure?",
      (response: any) => {
        setLoading(true);
        jobsService
          .deleteCandidateComment({
            expertId: props.expertId,
            noteId: noteId,
          })
          .then((res) => {
            setComments((prev) =>
              prev.filter((comment) => comment.noteId !== noteId)
            );
          })
          .catch(() => {
            toast.error(
              "Could not delete comment. Please try again.",
              DefaultToastSettings
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const setMessageFieldValue = (
    setFieldValue: (name: string, value: string) => void,
    newValue: string,
    mentions: MentionItem[]
  ) => {
    const trimmedText = newValue?.trim();
    if (trimmedText) {
      setMentions(mentions);
      setFieldValue("message", newValue);
    } else {
      setFieldValue("message", "");
    }
  };

  const userMentionData = useMemo(() => {
    let temp =
      teamMembers.map((team) => ({
        id: team.employeeId?.trim(),
        display: `${team.fullname?.trim()}`,
      })) ?? [];
    if (
      props.ownerExpert &&
      !temp?.find((temp) => temp.id === props.ownerExpert?.expertId)
    ) {
      temp = [
        {
          id: props.ownerExpert?.expertId?.trim(),
          display: props.ownerExpert?.fullname?.trim(),
        },
        ...temp,
      ];
    }
    temp = temp.filter((team) => team.display && team.id);
    return temp;
  }, [teamMembers]);

  return (
    <>
      <Main>
        <div className="previous-comments-container">
          <h4 style={{ fontSize: "14px", fontWeight: "700" }} className="mb-2">
            Previous Comments
          </h4>
          {loading ? (
            <div className="d-flex align-items-center">
              <Loader loading={loading} size={20} color={"blue"} />
              <span className="ml-3">fetching comments...</span>
            </div>
          ) : (
            <div>
              {(!comments || comments.length === 0) && <span>No comments</span>}
              {comments?.map((comment, idx) => (
                <MemoPrevCommentDisplay
                  teamMembers={teamMembers}
                  comment={comment}
                  deleteComment={deleteComment}
                  expertId={props.expertId}
                  key={comment.noteId + idx}
                />
              ))}
            </div>
          )}
        </div>
        <div className="user-input-container">
          <Formik
            initialValues={{ message: "" }}
            validationSchema={ValidationSchema}
            onSubmit={() => {}}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddCommentSubmit(values, setFieldValue);
                }}
              >
                <div style={{ marginBottom: "10px" }}>
                  <Field
                    className="form-control"
                    name={"message"}
                    id={"message"}
                    type="text"
                  >
                    {() => (
                      <ScrollableMention
                        onChange={(txt, planTxt, mentions) =>
                          setMessageFieldValue(setFieldValue, txt, mentions)
                        }
                        placeholder="Add comment, tag team with @ symbol."
                        trigger="@"
                        userMentionData={userMentionData}
                        value={values.message}
                        singleLine
                      />
                    )}
                  </Field>
                  {errors.message && touched.message && (
                    <div className="text-danger">{errors.message}</div>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: 20 }}
                  type="submit"
                  size="small"
                  className="mt-4"
                >
                  Post Comment
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Main>
    </>
  );
};

interface ReadMoreProps {
  children: string;
  maxCharacters?: number;
}

const convertMessage = (message: any) => {
  if (!message) return "No tasks";
  const regex = /@\[(.+?)\]\([^\)]+\)/g;
  return message.replace(regex, "**@$1**");
};

const ReadMore: React.FC<ReadMoreProps> = ({
  children,
  maxCharacters = 200,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded
    ? children
    : children.substring(0, maxCharacters);

  return (
    <div>
      <Typography style={{ fontSize: "13px" }}>
        {displayText}
        {!isExpanded && children.length > maxCharacters && "..."}
      </Typography>
      {children.length > maxCharacters && (
        <Button
          onClick={handleToggle}
          size="small"
          style={{ textTransform: "none", marginTop: "1px" }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
      )}
    </div>
  );
};

const PrevCommentDisplay = ({
  comment,
  teamMembers,
  deleteComment,
  expertId,
  key,
}: {
  comment: CommentObject;
  teamMembers: TeamMemberDataType[];
  deleteComment: (noteId: string) => void;
  expertId: string;
  key: string;
}) => {
  const [actionTask, setActionTask] = useState<{
    status: TASK_STATUS | null;
    taskId: string | null;
    jobId: string | null;
    taskedAssignedExpert: string | null;
  }>({ jobId: null, status: null, taskId: null, taskedAssignedExpert: null });
  const [taskAssigneeChangeEl, setTaskAssigneeChangeEl] = useState<any | null>(
    null
  );

  const [changeTaskStatusEl, setChangeTaskStatusEl] = useState<any | null>(
    null
  );

  const taggedMembers = comment.taggedTeamMembers ?? [];
  const isCurrentExpertTagged = taggedMembers.find(
    (memberId) => memberId === expertId
  );

  const setActionDataFromTask = (task: Task) => {
    if (!task) return;
    setActionTask({
      status: task.status,
      jobId: task.jobId,
      taskId: task.taskId,
      taskedAssignedExpert: task.expertId,
    });
  };

  const resetActionData = () => {
    setActionTask({
      status: null,
      jobId: null,
      taskId: null,
      taskedAssignedExpert: null,
    });
  };

  const changeTaskStatus = async (
    taskId: string,
    jobId: string,
    newStatus: TASK_STATUS
  ) => {
    try {
      await jobsService.updateTasks({
        expertId,
        taskId,
        jobId,
        status: newStatus,
      });
      toast.success("Task updated!");
    } catch (error) {
      toast.error("Failed to update task, please try again.");
    }
  };

  const changeTaskAssignee = async (
    taskId: string,
    jobId: string,
    updateExpertIdToAssignTask: string
  ) => {
    try {
      await jobsService.updateTasks({
        expertId,
        taskId,
        jobId,
        updateExpertIdToAssignTask,
      });
      toast.success("Task updated!");
    } catch (error: any) {
      toast.error("Failed to update task, please try again.");
    }
  };

  return (
    <div
      className="comment-display-container"
      style={{
        padding: "0.5rem",
        marginTop: "16px",
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid
          container
          alignItems="center"
          item
          xs={9}
          spacing={1}
          style={{ gap: "8px" }}
        >
          <Grid item>
            <Avatar
              alt={comment.fullname}
              src={comment.photoURL}
              sx={{
                width: 32,
                height: 32,
              }}
            />
          </Grid>
          <Grid item>
            <div style={{ lineHeight: "1.2" }}>
              <Box display="flex" alignItems="center">
                <b
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  }}
                >
                  {comment.fullname}
                </b>
                {!!comment.candidateTasks?.length && (
                  <Alert
                    title={formatLabel(
                      comment.candidateTasks?.[0]?.status || ""
                    )}
                    icon={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "12px",
                          height: "12px",
                        }}
                      >
                        <TaskStatusToIcon
                          status={comment.candidateTasks?.[0]?.status}
                        />
                      </div>
                    }
                    style={{
                      fontSize: "0.75rem",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                    severity="success"
                  />
                )}
              </Box>
              {comment.updatedAt && (
                <span style={{ fontSize: "0.75rem", color: "#888" }}>
                  {getDateTimeInLocalFormat(comment.updatedAt)}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3}
          justifyContent="flex-end"
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          {!!comment.candidateTasks?.length && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={ActionToolTipId + key + "Shuffle"}>
                  Change Task Status
                </Tooltip>
              }
            >
              <Shuffle
                style={{
                  color: "#388E3C",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setActionDataFromTask(comment.candidateTasks?.[0]);
                  setChangeTaskStatusEl(e.currentTarget);
                }}
              />
            </OverlayTrigger>
          )}
          {!!comment.candidateTasks?.length && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={ActionToolTipId + key + "PersonSwap"}>
                  Change Assignee
                </Tooltip>
              }
            >
              <PersonSwap
                style={{
                  color: "#FFA500",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setActionDataFromTask(comment.candidateTasks?.[0]);
                  setTaskAssigneeChangeEl(e.currentTarget);
                }}
              />
            </OverlayTrigger>
          )}
          {expertId === comment.expertId && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#EB5757",
                "&:hover": { backgroundColor: "#d32f2f" },
                width: 24,
                height: 24,
                color: "white",
              }}
              onClick={() => deleteComment(comment.noteId)}
            >
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: "8px",
          fontSize: "14px",
          color: "#333",
          background: "#f0f3fa",
          padding: "5px",
          borderRadius: "6px",
        }}
      >
        <ReadMore maxCharacters={180}>
          {convertMessage(comment.message)}
        </ReadMore>
        {/* <ScrollableMention
            onChange={(txt, plainTxt, mentions) => {}}
            placeholder=""
            trigger=""
            userMentionData={[]}
            value={comment.message}
            disable={true}
            highlighted={Boolean(isCurrentExpertTagged)}
            singleLine
          /> */}
      </div>

      <Menu
        anchorEl={changeTaskStatusEl}
        open={Boolean(changeTaskStatusEl)}
        onClose={() => {
          resetActionData();
          setChangeTaskStatusEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClick={(e) => {
          resetActionData();
          setChangeTaskStatusEl(null);
        }}
      >
        <MenuItem disabled>Select new status</MenuItem>
        {actionTask.status &&
          TaskStatusToKeyArray.map((status, idx) => {
            if (actionTask.status === status.value) {
              return null;
            }
            return (
              <MenuItem
                onClick={() =>
                  changeTaskStatus(
                    actionTask.taskId!,
                    comment.jobId!,
                    status.value
                  )
                }
                key={idx + status.value + "change_status"}
              >
                <TaskStatusToIcon
                  status={status.value}
                  label={StatusToDisplayText[status.value]}
                />
              </MenuItem>
            );
          })}
      </Menu>

      <Menu
        anchorEl={taskAssigneeChangeEl}
        open={Boolean(taskAssigneeChangeEl)}
        onClose={() => {
          resetActionData();
          setTaskAssigneeChangeEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem disabled={true}>Change Assignee</MenuItem>
        <div style={{ padding: "8px", width: "300px" }}>
          <Autocomplete
            options={teamMembers}
            getOptionLabel={(teamMember) => `${teamMember.fullname}`}
            getOptionDisabled={(teamMember) =>
              teamMember.employeeId === actionTask.taskedAssignedExpert!
            }
            onChange={(e, value) => {
              changeTaskAssignee(
                actionTask.taskId!,
                comment.jobId!,
                value?.employeeId!
              );
              resetActionData();
              setTaskAssigneeChangeEl(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Assignee"
                size="small"
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "200px",
              },
            }}
          />
        </div>
      </Menu>
    </div>
  );
};

const MemoPrevCommentDisplay = React.memo(
  PrevCommentDisplay,
  (prev, next) => prev.comment.noteId === next.comment.noteId
);

export default AddCommentModal;
