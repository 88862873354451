import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { LeftIcon } from "containers/Feedback/TabContent/Submissions/SubmissionDetail/SubmissionDetail.styles";
import React, { useState } from "react";
import { MoreVerticalOutline } from "styled-icons/evaicons-outline";
import { getOnlyDateTimeInLocalFormat } from "utilities/commonUtils";
import { Campaign } from "../types";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { SideListIcon } from "assets/index";
import { updateCampaignStatus } from "services/jobs";


const ACTION_BUTTON_STYLE = {
  width: "20px",
  cursor: "pointer",
  color: "#315cd5",
};

interface CampaignCardProps {
  singleCampaign: Campaign;
  expertId: string;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ singleCampaign, expertId }) => {
  console.log(singleCampaign)
  const [isChecked, setIsChecked] = useState(
    singleCampaign.campaignStatus !== "NOT_ACTIVE"
  );
  const [localLoader, setLocalLoader] = useState<boolean>(false);


  const handleToggle = async () => {
    const newStatus = isChecked ? "NOT_ACTIVE" : "ACTIVE";
  
    try {
      setLocalLoader(true);
  
      const response = await updateCampaignStatus(
        expertId,
        singleCampaign.campaignId.toString(),
        newStatus
      );
  
      if (response && response.ok) {
        setIsChecked(!isChecked);
      } else {
        console.error("Failed to update status", response?.statusText || "Unknown error");
      }
    } catch (error:any) {
      console.error(error.message);
    } finally {
      setLocalLoader(false);
    }
  };
  
  
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <OverlayLoader loading={localLoader} />
      <Box display="flex" alignItems="center" gap="16px">
        <Box
          height="50px"
          borderRadius="8px"
          bgcolor="#f5f5f5"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src="https://via.placeholder.com/40" alt="Icon" />
        </Box>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#315cd5",
              }}
            >
              {singleCampaign.campaignName}
            </Typography>
            <Tooltip title="Info about DevOps Coach" arrow>
              <IconButton
                size="small"
                style={{ padding: "0px", marginLeft: "4px" }}
              >
                <LeftIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            position="relative"
            width="50%"
            height={8}
            borderRadius="4px"
            bgcolor="#e0e0e0"
          >
            <Box
              position="absolute"
              width={`${(2 / 21) * 100}%`}
              height="100%"
              bgcolor="green"
              borderRadius="4px"
            />
           <Box
              position="absolute"
              left={`${((singleCampaign?.analytics?.getting_ready_leads ?? 0) / 21) * 100}%`}
              width={`${((singleCampaign?.analytics?.finished_leads ?? 0) / 21) * 100}%`}
              height="100%"
              bgcolor="orange"
              borderRadius="4px"
            />

            <Box display="flex" justifyContent="space-between" marginTop="6px">
              <Typography variant="caption" color="green" marginTop="10px">
                {singleCampaign?.analytics?.getting_ready_leads}
              </Typography>
              <Typography variant="caption" color="orange" marginTop="10px">
              {singleCampaign?.analytics?.finished_leads}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" marginRight="16px">
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography sx={{ color: "#315cd5" }}>
            <img style={{ top: '4px', right: '0', width: '19px', height: "19px" }} alt="icon" src={SideListIcon} />
            {singleCampaign.analytics?.leads_list_size ?? 0}
          </Typography>
        </Box>
        <Typography variant="caption" color="gray">
          Lists of leads
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box textAlign="center">
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography variant="h6">
            {singleCampaign.analytics?.acceptance_rate ?? 0}%
          </Typography>
          <Typography variant="h6" sx={{ color: "#315cd5" }}>
            {singleCampaign.analytics?.acceptance_rate_leads ?? 0}
          </Typography>
        </Box>
        <Typography variant="caption" color="gray">
          Acceptance Rate
        </Typography>
      </Box>

      <Box textAlign="center">
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography variant="h6">
            {singleCampaign.analytics?.reply_rate ?? 0}%
          </Typography>
          <Typography variant="h6" sx={{ color: "#315cd5" }}>
            {singleCampaign.analytics?.reply_rate_leads ?? 0}
          </Typography>
        </Box>
        <Typography variant="caption" color="gray">
          Reply rate
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Box sx={{ display: "flex" }} textAlign="start">
          <Box>
            <Typography variant="h6" color="gray">
              {getOnlyDateTimeInLocalFormat(new Date(singleCampaign.createdAt))}
            </Typography>
            <Typography variant="caption" color="gray">
              Created
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box>
          <Typography variant="subtitle1">
            {isChecked ? "Active" : "Not Active"}
          </Typography>
          <Switch
            checked={isChecked}
            onChange={handleToggle}
          />
          <IconButton>
            <MoreVerticalOutline style={{ ...ACTION_BUTTON_STYLE }} />
          </IconButton>
        </Box>
        <Menu open={false}>
          <MenuItem>Option 1</MenuItem>
          <MenuItem>Option 2</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default CampaignCard;
