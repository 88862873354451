import OverflowTip from 'components/Common/OverflowTip/OverflowTip';
import { SmallSpan } from 'components/CommonStyles';
import { employmentTypes, jobTypes } from "containers/HiringManager/Jobs.util";
import { isNumber } from "lodash";
import { compensationOptions } from "pages/B2B/constants";
import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { Candidates_Count, EquityType, JobType, LocationType } from "types/JobManagement";
import { formatNumberToShortenedForm } from 'utilities/commonUtils';

const StyledScoreTableContainer = styled.div<{ mutedBorder: boolean }>`
    min-width: 600px;
    margin-bottom: 16px;
    flex-basis: 60%;
    table, th, td {
        border: ${(props: any) => (props.mutedBorder ? "4px solid #ffffff" : "4px solid #f8f8f8")} !important;
    }
    th, td {
        padding: 0.5rem 1rem;
        text-align: center;
        vertical-align: top;
    }
`

const StyledPersonalInfoTableContainer = styled.div<{}>`
    min-width: 400px;
    margin-bottom: 16px;
    flex-basis: 40%;
    th, td {
        padding: 0.5rem 1rem;
        text-align: start;
        vertical-align: top;
    }
    span {
        display: block;
    }
    .ellipsis-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }
`

const TooltipRendererContainer = styled.div`
    .tooltip-inner {
        min-width: 25rem;
    }
`

const JobAdditionalInfo = (props: {
    mutedBorder: boolean;
    jobAnalytics: Candidates_Count[],
    jobType: string,
    locations: LocationType[],
    employmentType: string,
    minSalary?: number,
    maxSalary?: number,
    equity: EquityType,
    loadingAnalytics: boolean,
    currency?: string,
    numberOfDaysOfficeEveryWeek?: number,
    startTime?: string,
    endTime?: string,
    agencyRewardFixedAmount?: number;
    agencyRewardPercentageOfAnnualSalary?: number;
    agencyRewardPercentageOfHourlyRate?: number;
}) => {

    const tooltipRendererContainerRef = useRef<HTMLDivElement | null>(null);

    const getTotalCandidates = (candidateCounts: Candidates_Count) => {
        let sum: number = 0;
        const keys = Object.keys(candidateCounts) as (keyof Candidates_Count)[];
        for (let key of keys) {
            let value = (isNumber(candidateCounts[key]) ? candidateCounts[key] : 0);
            if (isNumber(value)) {
                sum = sum + value;
            }
        }
        return sum;
    }

    const getLocations = (locations: LocationType[]): string[] => {
        if (!locations || locations.length === 0) {
            return ['-'];
        }
        const locationStrings = [];
        for (let location of locations) {
            const tempArr = [];
            location.countryName && tempArr.push(location.countryName);
            location.state?.stateName && tempArr.push(location.state?.stateName);
            location.state?.city?.cityId && tempArr.push(location.state?.city?.cityId);
            locationStrings.push(tempArr.join(', '));
        }
        return locationStrings;
    }

    const jobTypeKey: JobType = jobTypes.find(type => type.key === props.jobType)?.key as JobType ?? '-';
    const jobTypeValue = jobTypes.find(type => type.key === props.jobType)?.value ?? '-';

    return (
        <div className='d-flex align-items-center justify-content-start'>
            <TooltipRendererContainer ref={tooltipRendererContainerRef} />
            <StyledScoreTableContainer mutedBorder={props.mutedBorder}>
                <table>
                    <tr>
                        <td className='text-left text-mutedBorder'>Candidates</td>
                        <td className='text-mutedBorder'>Total</td>
                        <td className='text-mutedBorder'>Talent Pool</td>
                        <td className='text-mutedBorder'>Coach & Client Eval.</td>
                        <td className='text-mutedBorder'>Selected</td>
                        <td className='text-mutedBorder'>Rejected</td>
                    </tr>
                    {props.jobAnalytics?.length > 0 ? props.jobAnalytics.map(cnt => {
                        return (
                            <tr>
                                <td className='text-left text-mutedBorder'>{cnt?.companyName}</td>
                                <td>{getTotalCandidates(cnt)}</td>
                                <td>{(cnt?.talentPool + cnt?.preVetted + cnt?.vetted) ?? '-'}</td>
                                <td>{cnt?.interviewRequest ?? '-'}</td>
                                <td>{cnt?.finalized ?? '-'}</td>
                                <td>{cnt?.rejected ?? '-'}</td>
                            </tr>
                        )
                    }) : <tr>
                        <td colSpan={6}>{props.loadingAnalytics ? <ClipLoader
                            color='#315CD5'
                            size={20}
                            loading={true}
                            speedMultiplier={4}
                        /> : 'No Data'}</td>
                    </tr>}
                </table>
            </StyledScoreTableContainer>
            <StyledPersonalInfoTableContainer>
                <table>
                    <tr>
                        <td>
                            <SmallSpan>Job Type:</SmallSpan>
                            <span>{jobTypeKey === "HYBRID" ? `${jobTypeValue} ${isNumber(props.numberOfDaysOfficeEveryWeek) ? `(${props.numberOfDaysOfficeEveryWeek})` : ""}` : jobTypeValue}</span>
                        </td>
                        {jobTypeKey !== "REMOTE" && <td>
                            <SmallSpan>Location:</SmallSpan>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip
                                        id={'job-additional-info-location'}
                                    >
                                        <div className="text-left">
                                            <b className="d-block">Location: </b>
                                            {getLocations(props.locations).map((location, idx) => {
                                                return (
                                                    <span key={location + idx} className="d-block">{location};</span>
                                                )
                                            })}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span className="ellipsis-text">{getLocations(props.locations).join(", ")}</span>
                            </OverlayTrigger>
                        </td>}
                        {jobTypeKey === "REMOTE" && <td>
                            <SmallSpan>Time Overlap:</SmallSpan>
                            <span>{(props.startTime && props.endTime) ? `${props.startTime} - ${props.endTime}` : "-"}</span>
                        </td>}
                        <td>
                            <SmallSpan>Placement Reward:</SmallSpan>
                            {isNumber(props.agencyRewardFixedAmount) && <OverlayTrigger
                                placement="bottom"
                                container={tooltipRendererContainerRef.current}
                                overlay={
                                    <Tooltip
                                        id={'job-additional-info-agencyRewardFixedAmount'}
                                        style={{ minWidth: '200px' }}
                                    >
                                        <div className="text-left">
                                            <b className="d-block">Placement Reward: </b>
                                            {`*${props.agencyRewardFixedAmount} ${props.currency} upon successful placement & completion of 90 days.`}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{props.agencyRewardFixedAmount} {props.currency}</span>
                            </OverlayTrigger>}
                            {isNumber(props.agencyRewardPercentageOfAnnualSalary) && <OverlayTrigger
                                placement="bottom"
                                container={tooltipRendererContainerRef.current}
                                overlay={
                                    <Tooltip
                                        id={'job-additional-info-agencyRewardPercentageOfAnnualSalary'}
                                        style={{ minWidth: '200px' }}
                                    >
                                        <div className="text-left">
                                            <b className="d-block">Placement Reward: </b>
                                            {`*${props.agencyRewardPercentageOfAnnualSalary}% of the candidate's annual salary upon successful placement & completion of 90 days.`}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{props.agencyRewardPercentageOfAnnualSalary}% of annual salary*</span>
                            </OverlayTrigger>}
                            {isNumber(props.agencyRewardPercentageOfHourlyRate) && <OverlayTrigger
                                placement="bottom"
                                container={tooltipRendererContainerRef.current}
                                overlay={
                                    <Tooltip
                                        id={'job-additional-info-agencyRewardPercentageOfHourlyRate'}
                                        style={{ minWidth: '200px' }}
                                    >
                                        <div className="text-left">
                                            <b className="d-block">Placement Reward: </b>
                                            {`*${props.agencyRewardPercentageOfHourlyRate}% of the candidate's the candidate's hourly rate`}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <span>{props.agencyRewardPercentageOfHourlyRate}% of hourly salary*</span>
                            </OverlayTrigger>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SmallSpan>Employment Type:</SmallSpan>
                            <span>{employmentTypes.find(type => props.employmentType === type.key)?.value ?? '-'}</span>
                        </td>
                        <td>
                            <SmallSpan>Salary:</SmallSpan>
                            {props.equity !== "ONLY_EQUITY" ? <span>{`${props.minSalary ? formatNumberToShortenedForm(props.minSalary) : 0} ${props.currency} - ${props.maxSalary ? formatNumberToShortenedForm(props.maxSalary) : 0} ${props.currency} per year`}</span> : <span>-</span>}
                        </td>
                        <td>
                            <SmallSpan>Compensation:</SmallSpan>
                            <span>{compensationOptions.find(option => option.key === props.equity)?.label ?? '-'}</span>
                        </td>
                    </tr>
                </table>
            </StyledPersonalInfoTableContainer>
        </div>
    )
}

export default JobAdditionalInfo;