import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchCampaignAndAnalytics } from "actions/campaign/campaignActions";
import { useLocation } from "react-router";
import { DEFAULT_TOKEN } from "utilities/constants";

const useCampaignData = (expertId: string) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { campaignData, analyticsData, combinedLeadsData, loading, error } =
    useSelector((state: RootState) => state.campaign);

  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get("jobId") || "";

  useEffect(() => {
    if (!campaignData || !analyticsData || !combinedLeadsData) {
      const payload = {
        jobId,
        expertId,
        token: DEFAULT_TOKEN,
      };
      dispatch(fetchCampaignAndAnalytics(payload));
    }
  }, [campaignData, analyticsData, combinedLeadsData, dispatch, expertId]);

  return { campaignData, analyticsData, combinedLeadsData, loading, error };
};

export default useCampaignData;
