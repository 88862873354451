import {
  Button,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material";
import RichTextEditor from "components/Common/Editors/RichTextEditor";
import { ModalComponent } from "components/Modals/Modal";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { jobsService } from "services/jobs";
import styled from "styled-components";
import { DefaultToastSettings } from "utilities/defaults";
import { removeHTML } from "utilities/utils";
import * as Yup from "yup";
import EmailAutoComplete from "components/Common/EmailAutoComplete/EmailAutoComplete";
import Loader from "react-spinners/ClipLoader";
import { CandidateDetails } from "types/jobPipeline";
import { getCandidateDetails } from "services/jobPipeline";
import CandidateResumeComponent from "containers/JobPipeline/components/Resume/CandidateResumeComponent";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { renderAsync } from "docx-preview";

const Main = styled.div`
  padding: 1.5rem;

  .link-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease;
    }

    a:hover {
      color: #0056b3;
    }
  }
`;

const ResumeModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 9990 !important;
`;

const ResumeContainer = styled(Box)`
  position: relative;
  width: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 90vh;
  z-index: 9999 !important;
`;
const DocxWrapper = styled.div`
  .docx-wrapper {
    flex-flow: initial !important;
    align-items: initial !important;
  }
`;

const ValidationSchema = Yup.object({
  subject: Yup.string().required("Subject is required"),
  body: Yup.string().required("Body is required"),
  attach: Yup.string().required("Please select which resume you want to attach"),
  emails: Yup.array()
    .of(Yup.string().email("Invalid email address"))
    .min(1, "At least one email is required")
    .required("Email list is required"),
});

type IProps = {
  handleClose: (e: boolean) => void;
  show: boolean;
  expertId: string;
  jobId: string;
  candidateId: string;
  backdropClassName?: string;
  incrementReminderCount: () => void;
};

const SendSubmission = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openAIResumeModal, setOpenAIResumeModal] = useState<boolean>(false);
  const [candidateDetails, setCandidateDetails] =
    useState<CandidateDetails | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isDocx, setIsDocx] = useState(false);
  const docxContainerRef = useRef<HTMLDivElement>(null);
  const [resumeUrl, setResumeUrl] = useState<string | null>(null);
  const [emailACOptions, setEmailACOptions] = useState<string[]>([]);

  const handleAIModalClose = () => {
    setOpenAIResumeModal(false);
  };

  const [formData, setFormData] = useState<{
    emails: string[];
    subject: string;
    body: string;
    attach: string;
  }>({
    emails: [], 
    subject: "",
    body: "",
    attach: "",
  });

  useEffect(() => {
    fetchCandidateSubmissionDetails();
  }, []);

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await getCandidateDetails(
          props.expertId,
          props.candidateId,
          props.jobId
        );

        if (response && response.candidates) {
          setCandidateDetails(response.candidates);
        }
      } catch (error) {
      
      }
    };

    if (props.candidateId && props.expertId && props.jobId) {
      fetchCandidateDetails();
    }
  }, []);

  const handleViewResume = async (url: string) => {
    if (!url) {
      toast.error("Resume URL is not available");
      return;
    }

    const urlPath = url.split("?")[0];
    const fileExtension = urlPath.split(".").pop()?.toLowerCase();

    setResumeUrl(url);
    setShowModal(true);
    setLoading(true);

    if (fileExtension === "docx") {
      setIsDocx(true);
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        if (docxContainerRef.current) {
          renderAsync(arrayBuffer, docxContainerRef.current);
        }
      } catch (error) {
        toast.error("Failed to load DOCX file");
      } finally {
        setLoading(false);
      }
    } else if (fileExtension === "pdf") {
      setIsDocx(false);
      setLoading(false);
    } else {
      toast.error("Unsupported file format for preview");
      setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchCandidateSubmissionDetails = async () => {
    setLoading(true);
    try {
      const payload = {
        expertId: props.expertId,
        jobId: props.jobId,
        candidateId: props.candidateId,
      };
  
      const response = await jobsService.getCandidateSubmissionDetails(payload);
  
      if (response?.apiStatus === "SUCCESS") {
        let emails;
        if (response.output.toEmails && response.output.toEmails.length > 0){
          emails = response.output.toEmails;
        } else {
          emails = [];
        }
  
        setFormData({
          emails: emails, 
          subject: response.output.emailDetails.subject || "",
          body: response.output.emailDetails.body || "",
          attach: "",
        });

        setEmailACOptions(emails);
      } else {
        toast.error("Failed to fetch candidate details", DefaultToastSettings);
      }
    } catch (err) {
      toast.error("Something went wrong, please try again", DefaultToastSettings);
    } finally {
      setLoading(false);
    }
  };
    
  

  const setMessageFieldValue = (
    setFieldValue: (name: string, value: string) => void,
    data: string
  ) => {
    setFieldValue("body", data || "");
  };

  const handleSendCandidateSubmission = async (formData: any) => {
    setLoading(true);
    try {
      const payload = {
        expertId: props.expertId,
        jobId: props.jobId,
        candidateId: props.candidateId,
        toEmails: formData.emails,
        emailDetails: {
          subject: formData.subject,
          body: formData.body,
          attachResume: formData.attach || "", 
        },
      };

      const response = await jobsService.sendCandidateSubmission(payload);

      if (response?.apiStatus === "SUCCESS") {
        toast.success("Candidate submission sent successfully");
        props.handleClose(true);
      } else {
        toast.error("Failed to send candidate submission");
      }
    } catch (err) {
      toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalComponent
      header={"Send Submission"}
      modalStyle={{ zIndex: "9900" }}
      handleClose={() => props.handleClose(false)}
      show={props.show}
      showCloseIcon={true}
      backdropClassName={props.backdropClassName}
      size="xl"
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Loader size={50} color="#007bff" loading={loading} />
        </div>
      ) : (
        <Main>
          <Formik
            initialValues={formData}
            enableReinitialize
            onSubmit={handleSendCandidateSubmission}
            validationSchema={ValidationSchema}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="mb-3">
                 <EmailAutoComplete
                    emailOptions={emailACOptions} 
                    onEmailsChange={(updatedEmails: string[]) => {
                      setFieldValue("emails", updatedEmails); 
                    }}
                  />
                  {errors.emails && touched.emails && (
                    <div className="text-danger">{errors.emails}</div>
                  )}
                </div>

                <div className="mb-3">
                  <Field
                    name="subject"
                    title="Enter subject"
                    type="text"
                    className="form-control"
                    placeholder="Enter subject"
                  />
                  {errors.subject && touched.subject && (
                    <div className="text-danger">{errors.subject}</div>
                  )}
                </div>
                <div className="mb-3">
                  <Field name="body">
                    {() => (
                      <RichTextEditor
                        value={values.body}
                        disabled={false}
                        onChange={(data: string) =>
                          setMessageFieldValue(setFieldValue, data)
                        }
                        id="body"
                        customStyles={{ height: "150px", boxShadow: "none" }}
                        placeholder="Enter email body"
                      />
                    )}
                  </Field>
                  {errors.body && touched.body && (
                    <div className="text-danger">{errors.body}</div>
                  )}
                </div>
                <div className="link-container">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewResume(candidateDetails?.resumeUrl || "");
                    }}
                  >
                    Preview Original Resume
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenAIResumeModal(true);
                    }}
                  >
                    Preview AI Generated Resume
                  </a>
                </div>

                <ResumeModal open={showModal} onClose={handleCloseModal}>
                  <>
                    <ResumeContainer>
                      {loading ? (
                        <CircularProgress />
                      ) : !isDocx ? (
                        resumeUrl && (
                          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer fileUrl={resumeUrl || ""} />
                          </Worker>
                        )
                      ) : (
                        <DocxWrapper>
                          <div
                            ref={docxContainerRef}
                            style={{ padding: "20px" }}
                          />
                        </DocxWrapper>
                      )}
                    </ResumeContainer>

                    <Box
                      mt={2}
                      textAlign="right"
                      position="absolute"
                      top="0"
                      right="0"
                    >
                      <Button
                        style={{color:"#66615e", fontSize:"24px" , background:"white" ,opacity:"0.4"}}
                        onClick={handleCloseModal}
                      >
                        &#10005;
                      </Button>
                    </Box>
                  </>
                </ResumeModal>
                <ResumeModal
                  open={openAIResumeModal}
                  onClose={handleAIModalClose}
                >
                  <>
                  <ResumeContainer>

                    <CandidateResumeComponent
                      open={openAIResumeModal}
                      handleClose={handleAIModalClose}
                      candidateDetails={candidateDetails}
                      expertId={props.expertId}
                      candidateId={props.candidateId}
                      jobId={props.jobId}        
                      />
                      </ResumeContainer>
                      <Box
                      mt={2}
                      textAlign="right"
                      position="absolute"
                      top="0"
                      right="0"
                      >
                      <Button
                        style={{color:"#66615e", fontSize:"24px" , background:"white" ,opacity:"0.4"}}
                        onClick={handleCloseModal}
                        >
                        &#10005;
                      </Button>
                    </Box>
                  </>
                </ResumeModal>
                <div className="mb-3" style={{ marginTop: "10px" }}>
                  <Field
                    name="attach"
                    as="select"
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      textAlign: "left",
                    }}
                  >
                    <option value="">Choose Resume</option>
                    <option value="RESUME">Attach Original Resume</option>
                    <option value="AI_RESUME">Attach AI Generated Resume</option>
                  </Field>
                  {errors.attach && touched.attach && (
                    <div className="text-danger">{errors.attach}</div>
                  )}
                </div>
                <div style={{ marginTop: "10px" }} className="mb-3">
                  <button className="btn btn-primary" type="submit">
                    Send for Submission
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Main>
      )}
    </ModalComponent>
  );
};

export default SendSubmission;
