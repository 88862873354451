import { Avatar, Grid } from '@mui/material';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import { ModalComponent } from 'components/Modals/Modal';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { jobsService } from 'services/jobs';
import styled from 'styled-components';
import { RemindersObject } from 'types/Jobs';
import { getDateTimeInLocalFormat } from 'utilities/commonUtils';
import { DefaultToastSettings } from 'utilities/defaults';
import { removeHTML } from 'utilities/utils';
import * as Yup from 'yup';

const Main = styled.div`
    padding: 1.5rem;
    .reminder-display-container {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
`;

const ValidationSchema = Yup.object({
    subject: Yup.string()
        .required("Subject is required"),
    title: Yup.string()
        .required("Title is required"),
});

type IProps = {
    handleClose: (e: boolean) => void;
    show: boolean;
    expertId: string,
    jobId: string,
    candidateId: string,
    backdropClassName?: string;
    incrementReminderCount: () => void;
}

const SendCustomReminder = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reminders, setReminders] = useState<RemindersObject[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getCandidateReminder();
    }, [])

    const getCandidateReminder = () => {
        setLoading(true);
        jobsService.getCandidateReminder({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId
        }).then((res) => {
            setReminders([...res.output]);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSendCustomReminder = (formData: { subject: string, title: string }) => {
        setLoading(true);
        jobsService.sendCustomReminder({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId,
            subject: formData.subject,
            title: formData.title
        }).then((res) => {
            toast.success("Reminder sent successfully", DefaultToastSettings);
            setReminders(prev => ([{ ...res.output }, ...prev]));
            props.incrementReminderCount();
        }).catch(e => {
            toast.error("Something went wrong, please try again", DefaultToastSettings);
        }).finally(() => {
            setLoading(false);
        });
    }

    const setMessageFieldValue = (setFieldValue: (name: string, value: string) => void, data: string) => {
        const onlyUserText = removeHTML(data);
        if (onlyUserText) {
            setFieldValue('subject', data);
        } else {
            setFieldValue('subject', "");
        }
    }

    return (
        <ModalComponent
            header={"Send Custom Reminder"}
            modalStyle={{ zIndex: '9999' }}
            handleClose={() => props.handleClose(false)}
            show={props.show}
            showCloseIcon={true}
            backdropClassName={props.backdropClassName}
            size='xl'
        >
            <Main>
                <Formik
                    initialValues={{ subject: "", title: "" }}
                    onSubmit={handleSendCustomReminder}
                    validationSchema={ValidationSchema}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                            <div className='mb-3'>
                                <Field name='title' title='Enter title' type='text' className="form-control" placeholder="Enter title" />
                                {errors.title && touched.title && (
                                    <div className="text-danger">{errors.title}</div>
                                )}
                            </div>
                            <div className='mb-3'>
                                <Field
                                    className="form-control"
                                    name={'subject'}
                                    id={'subject'}
                                    type="text"
                                >
                                    {() => (
                                        <RichTextEditor
                                            value={values.subject}
                                            disabled={false}
                                            onChange={(data: string) => setMessageFieldValue(setFieldValue, data)}
                                            id={'subject'}
                                            customStyles={{ height: "150px", boxShadow: 'none' }}
                                            placeholder='Enter your subject'
                                        />
                                    )}
                                </Field>
                                {errors.subject && touched.subject && (
                                    <div className="text-danger">{errors.subject}</div>
                                )}
                            </div>
                            <button className="btn btn-primary" type="submit">
                                {"Send Custom Reminder"}
                            </button>
                        </Form>
                    )}
                </Formik>
                
            </Main>
        </ModalComponent>
    );
}


export default SendCustomReminder;