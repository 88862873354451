/** @jsxImportSource @emotion/react */
import { Box, CircularProgress, Step, StepButton, StepContent, Stepper, Typography } from '@mui/material';
import { CircleDot } from "@styled-icons/fa-solid/CircleDot";
import { AwsUploader } from 'components/Uploader/AwsUploader';
import { useJobPipeline } from 'containers/JobPipeline/contexts/JobPipelineContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCandidateEvaluationStatus, getResumeParsingStatus } from 'services/jobPipeline';
import { jobsService } from 'services/jobs';
import { RootState } from 'store';
import styled from 'styled-components';
import { CheckCircleOutline } from 'styled-icons/material';
import { ClientEvaluation, EvaluationData } from 'types/jobPipeline';
import AIEvaluationComponent from './AIEvaluationComponent';
import AIResumeEvaluationComponent, { ActionButton } from './AIResumeEvaluationComponent';
import CandidateCoachEvaluationComponent from './CandidateCoachEvaluationComponent';
import ClientEvaluationComponent from './ClientEvaluationComponent';
import EvaluationSelectionComponent from './EvaluationSelectionComponent';
import IntentEvaluationComponent from './IntentEvaluation';
import { Back } from 'styled-icons/entypo';
import {toast} from "react-toastify"

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  width: 24px;
  height: 24px;
  color: #4caf50;
`;

const StyledCheckCircleOrangeOutline = styled(CheckCircleOutline)`
  width: 24px;
  height: 24px;
  color: #FFA500;
`;

const StyledRadioButtonUnchecked = styled(CircleDot)`
  width: 24px;
  height: 24px;
  color: #757575;
`;

const POLLING_INTERVAL = 60000;

const CandidateEvaluationComponent: React.FC = () => {
    const { currentJob, activeCandidateProfile, loadJobs } = useJobPipeline();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<Set<number>>(new Set());
    const [evaluationData, setEvaluationData] = useState<EvaluationData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);
    const [parsingTimer, setParsingTimer] = useState<number>(1);
    const [evalfileUploadedAt, setEvalfileUploadedAt] = useState<number>(0);
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const [showUploader, setShowUploader] = useState<boolean>(false);
    const [hasUploaded, setHasUploaded] = useState<boolean>(false);
    const candidateId = activeCandidateProfile?.candidateId || '';
    const trackId = currentJob?.trackId || '';
    const attachmentsDir = `${candidateId}/Resumes/${trackId}`;
    const [uploadFileName, setUploadFileName] = useState<string | null>(null)

    const fetchEvaluationStatus = useCallback(async () => {
        if (candidateId && currentJob?.jobId && expertId) {
            try {
                setLoading(true);
                setNoData(false);
                const data = await getCandidateEvaluationStatus(expertId, currentJob.jobId, candidateId);

                if (data) {
                    setEvaluationData(data);

                    if (data.aiResumeEvaluation?.resumeParsingCompleted !== undefined) {
                        setIsPolling(!data.aiResumeEvaluation.resumeParsingCompleted);
                    }

                    if (data.aiResumeEvaluation && data.aiResumeEvaluation?.resumeUrl) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
                    }

                    if (data.aiEvaluation?.completedAt) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 1]));
                    }

                    if (data.intentEvaluation?.every(i => !!i.completedOn)) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 2]));
                    }

                    if (data.coachEvaluation?.completedOn) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 3]));
                    }

                    if (data.clientEvaluation && (data.clientEvaluation as ClientEvaluation[]).every(
                        ev => ev.completedOn && ev.feedback.trim() !== ''
                    )) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 4]));
                    }

                    if (data.selectionStatus?.feedback) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 5]));
                    }

                    if (!data.aiResumeEvaluation?.resumeUrl && hasUploaded) {
                        setIsPolling(true);
                    }
                } else {
                    setNoData(true);
                }
            } catch (error) {
                console.error("Failed to fetch candidate evaluation status:", error);
                setNoData(true);
            } finally {
                setLoading(false);
            }
        }
    }, [expertId, currentJob, candidateId, hasUploaded]);

    useEffect(() => {
        fetchEvaluationStatus();
    }, [fetchEvaluationStatus]);

    const handleEvaluationSuccess = () => {
        setCompleted(prevCompleted => new Set([...prevCompleted, 2]));
        setActiveStep(-1);
        fetchEvaluationStatus();
        if (currentJob?.jobId) {
            loadJobs(currentJob.jobId);
        }
    };

    const pollResumeParsingStatus = useCallback(async (ignoreIncrement: boolean = false) => {
        if (candidateId && expertId) {
            try {
                const response: any = await getResumeParsingStatus(expertId, candidateId,);
                if (response?.resumeParsingCompleted) {
                    if (response?.resumeParsingStatus === "COMPLETED"){
                        toast.success("Resume uploaded and parsed successfully")
                    } else {
                        toast.error("Resume uploaded and failed to parse it. Please try again!")
                    }
                    
                    await fetchEvaluationStatus();
                    setIsPolling(false);
                    setShowUploader(false);
                } else {
                    if (!ignoreIncrement){
                        setParsingTimer(prevTimer => prevTimer + 1);
                    }                    
                }
            } catch (error) {
                console.error('Error fetching resume parsing status:', error);
            }
        }
    }, [expertId, candidateId, fetchEvaluationStatus]);

    useEffect(() => {
        if (isPolling && activeStep === 0) {
            const interval = setInterval(() => {
                pollResumeParsingStatus(); 
            }, 1000); 
    
            return () => clearInterval(interval); 
        }
    }, [pollResumeParsingStatus, isPolling]);

    const handleUpdateResume = () => {
        setShowUploader(true);
        setParsingTimer(1);
        setIsPolling(false);
    };

    let fileUploadedAt: any;

    const handleImageUpload = async (fileUrl: string) => {
        try {
            console.log(`File uploaded successfully: ${fileUrl}`);
            const fileName = fileUrl.split('/').pop()
            setUploadFileName(fileName || "unknown file")
            await jobsService.updateCandidateDetails(expertId, candidateId, fileUrl);
            setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
            setActiveStep(0);
            setHasUploaded(true);
            setEvalfileUploadedAt(Date.now());
            setIsPolling(true);
            setShowUploader(false)
            await fetchEvaluationStatus();
        } catch (error) {
            toast.error("Failed to update file")
            console.error('Failed to update candidate details:', error);
        }
    };

    const isStepComplete = (step: number) => {
        if (step === 3) {
            if (
                (evaluationData?.coachEvaluation?.coachEvalScore ?? 0) > 0 &&
                (!evaluationData?.coachEvaluation?.meetingDetails || evaluationData?.coachEvaluation?.meetingDetails?.length === 0)
            ) {
                return 'green'; 
            }
    
            if (
                evaluationData?.coachEvaluation?.meetingDetails?.length > 0 &&
                evaluationData?.coachEvaluation?.meetingDetails.every((meeting: any) => meeting?.meetingStatus !== 'SCHEDULED')
            ) {
                return 'green'; 
            }
            if (
                evaluationData?.coachEvaluation?.meetingDetails?.length > 0 &&
                evaluationData?.coachEvaluation?.meetingDetails.some((meeting: any) => meeting?.meetingStatus === 'SCHEDULED')
            ) {
                return 'orange'; 
            }
    
            return 'gray'; 
        }
        return completed.has(step) ? 'green' : 'gray';
    };

    
    // if (loading) {
    //     return <Typography>Loading...</Typography>;
    // }

    if (noData || !evaluationData) {
        return <Typography>{(loading ? "Loading..." :  "No data available")}</Typography>;
    }


    const getLastUploadedTime = () => {
        return Math.floor(Math.round((Date.now() - evalfileUploadedAt)) / 1000);
    }

    const formatTime  = (Convert : number ) =>{
        const minutes = Math.floor(Convert / 60);
        const seconds = Convert % 60;
        return  minutes > 0 ? `${minutes}m : ${seconds}s` : `${seconds}s`
    }
    const steps = evaluationData ? [
        {
            label: evaluationData?.aiResumeEvaluation?.resumeUrl ? 'AI Resume Evaluation' : 'Upload Resume',
            description: (!showUploader && evaluationData?.aiResumeEvaluation?.resumeParsingCompleted === false) ? (
                <><Box display="flex" alignItems="center">
                    <CircularProgress size={20} />
                    <Typography sx={{ ml: 2 }}>Parsing in progress. {uploadFileName || "file"} uploaded {formatTime(getLastUploadedTime())} ago</Typography>

                </Box>
                <Box sx={{marginTop:"10px"}} >
                    <AIResumeEvaluationComponent
                        aiResumeEvaluation={evaluationData.aiResumeEvaluation}
                        onUpdateResume={handleUpdateResume} 
                        />
                </Box></>
            ) : (!showUploader && evaluationData?.aiResumeEvaluation?.resumeParsingCompleted) ? (
                <AIResumeEvaluationComponent
                    aiResumeEvaluation={evaluationData.aiResumeEvaluation}
                    onUpdateResume={handleUpdateResume}
                />
            ) : (
                <>
                    <Box display="flex" alignItems="center">
                        <AwsUploader
                            onUpload={handleImageUpload}
                            directory={attachmentsDir}
                            expertId={expertId}
                            allowAllUpload={true}
                        />
                    </Box>
                    {showUploader && <ActionButton
                        className='mt-2'
                        variant="contained"
                        onClick={() => {
                            if (evaluationData?.aiResumeEvaluation?.resumeParsingCompleted === false){
                                setIsPolling(true);
                            }
                            setShowUploader(false)
                        }}
                        startIcon={<Back size="24" />}
                    >
                        Back
                    </ActionButton>}
                </>
            ),
        },
        {
            label: 'AI Evaluation',
            description: evaluationData.aiEvaluation ? <AIEvaluationComponent aiEvaluation={evaluationData.aiEvaluation} /> : <>No Data</>,
        },
        {
            label: 'Intent Evaluation',
            description: (
                <IntentEvaluationComponent
                    expertId={expertId}
                    jobId={currentJob?.jobId || ''}
                    candidateId={activeCandidateProfile?.candidateId || ''}
                    onSubmitSuccess={handleEvaluationSuccess}
                    candidateIntentData={evaluationData?.intentEvaluation ? evaluationData.intentEvaluation : []}
                />
            ),
        },
        {
            label: 'Coach Evaluation',
            description: evaluationData.coachEvaluation ? (
                <CandidateCoachEvaluationComponent coachEvaluation={evaluationData.coachEvaluation} />
            ) : (
                <Typography variant="body2" color="textSecondary">
                    There is no coach evaluation in progress.
                </Typography>
            ),
        },
        {
            label: 'Client Evaluation',
            description: <ClientEvaluationComponent
                expertId={expertId}
                jobId={currentJob?.jobId || ''}
                candidateId={activeCandidateProfile?.candidateId || ''}
                evaluationData={evaluationData}
                onSubmitSuccess={handleEvaluationSuccess} />,
        },
        {
            label: 'Selection',
            description: (
                <EvaluationSelectionComponent
                    expertId={expertId}
                    jobId={currentJob?.jobId || ''}
                    candidateId={activeCandidateProfile?.candidateId || ''}
                    selectionStatus={evaluationData?.selectionStatus}
                    onSubmitSuccess={() => {
                        setCompleted((prevCompleted: any) => new Set([...prevCompleted, 5]));
                        setActiveStep(-1);
                        fetchEvaluationStatus();
                    }}
                />
            ),
        },
    ] : [];

    return (
        <Box>
            
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
    {steps?.map((step, index) => {

        const stepStatus = isStepComplete(index); 

        return (
            <Step key={step.label}>
                <StepButton
                    onClick={() => setActiveStep(index)}
                    icon={
                        index === 3 
                            ? (stepStatus === 'green'
                                ? <StyledCheckCircleOutline />
                                : (stepStatus === 'orange'
                                ? <StyledCheckCircleOrangeOutline />
                                : <StyledRadioButtonUnchecked />))
                            : completed.has(index) 
                            ? <StyledCheckCircleOutline />
                            : <StyledRadioButtonUnchecked />
                    }
                >
                    <Typography variant="subtitle1">{step.label}</Typography>
                </StepButton>
                <StepContent>{step.description}</StepContent>
            </Step>
        );
    })}
</Stepper>

        </Box>
       
    );
};

export default CandidateEvaluationComponent;