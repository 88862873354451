import { ListCheck } from "@styled-icons/bootstrap/ListCheck";
import { Edit, Expand, Highlight, Microphone, Plus, SelectMultiple, TrashAlt } from "@styled-icons/boxicons-regular";
import { CheckCircle } from "@styled-icons/boxicons-regular/CheckCircle";
import { Star } from "@styled-icons/material-sharp/Star";
import { HideSource } from "@styled-icons/material/HideSource";
import { IconContainer } from 'components/Common/IconContainer/IconContainer';
import {
    EllipsedSpan, NormalSpan,
    SmallSpan
} from 'components/CommonStyles';
import { ModalComponent } from "components/Modals/Modal";
import { SubmissionContent } from "containers/Feedback/TabContent/Submissions/Submissions.styles";
import { IMeetingQuestion } from "containers/Meeting/meetingTypes";
import { ModuleSelectionComponent } from "containers/Meeting/tab/components/ModuleSelectionComponent";
import { useLoader } from 'context/loaderContext';
import { useMessagePopup } from 'context/messagePopContext';
import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { meetingService } from 'services/meeting';
import { RootState } from "store";
import { QuestionTypes } from "utilities/constants";
import { AddCustomQuestion } from "./AddCustomQuestion";
import { MeetingEvaluation } from "./MeetingEvaluation";
import './index.css';


interface IMeetingQuestionComponent {
    candidateId: string,
    trackId: string,
    meetingDetailId: string,
    serviceType: string;
    onModuleSelectionClose: Function;
    onDelete: Function;
    readonly?: boolean;
    onQuestionAdd: Function;
    onQuestionEvaluated: Function;
    scrollToSubmitBtn: Function;
    meetingQuestions: IMeetingQuestion[];
    setMeetingQuestions: Function;
}

export const MeetingQuestionComponent = ({ scrollToSubmitBtn,
    onQuestionAdd,
    onQuestionEvaluated,
    candidateId,
    trackId,
    meetingDetailId,
    serviceType,
    onModuleSelectionClose,
    onDelete,
    readonly,
    meetingQuestions,
    setMeetingQuestions }: IMeetingQuestionComponent) => {
    const Loader = useLoader();
    const message = useMessagePopup();

    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [showCustomQuestionModal, setShowCustomQuestionModal] = useState<boolean>(false);
    const [showModuleSelectionModal, setShowModuleSelectionModal] = useState<boolean | undefined>(undefined);
    const [activeQuestion, setActiveQuestion] = useState<{ [x: string]: boolean }>({});
    const [expandedQuestion, setExpandedQuestion] = useState<IMeetingQuestion>();
    const [startRecording, setStartRecording] = useState<{ [x: string]: boolean }>({});
    const [customQuestionId, setCustomQuestionId] = useState<string>();
    const [showNotEvaluatedQuestions, setShowNotEvaluatedQuestions] = useState<boolean>(false);
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [enableMultipleSelection, setEnableMultipleSelection] = useState<boolean>(false);


    const handleRecordAnswer = (question: IMeetingQuestion) => {
        showQuestionEvaluations(question, true);
    }

    const handleEditQuestion = (question: IMeetingQuestion) => {
        setShowCustomQuestionModal(true);
        setCustomQuestionId(question.questionId);
    }

    const showQuestionEvaluations = (question: IMeetingQuestion, isRecording?: boolean) => {
        setActiveQuestion({ [question.questionId]: !activeQuestion[question.questionId] });
        setExpandedQuestion({ ...question });
        setStartRecording({ ...startRecording, [question.questionId]: !!isRecording });
    }

    const toggleQuestionSelection = (questionId: string) => {
        const ques = selectedQuestions.slice();
        const selectedQuestionInx = ques.indexOf(questionId);
        if (selectedQuestionInx > -1) {
            ques.splice(selectedQuestionInx, 1);
        } else {
            ques.push(questionId);
        }
        setSelectedQuestions(ques);
    }

    const confirmBeforeDeleteSelected = (questionId?: string) => {
        const text = "Are you sure you want to remove the selected question?";
        message.confirm(text, (response: any) => removeMultipleQuestions(questionId))
    }

    const removeMultipleQuestions = async (questionId?: string) => {
        Loader.showLoader();
        const filteredMeetingQuestions = meetingQuestions.filter(m => questionId === m.questionId || selectedQuestions.some(q => q === m.questionId));
        const questionsWithCapabilities = [];
        for (let i = 0; i < filteredMeetingQuestions.length; i++) {
            const q = filteredMeetingQuestions[i];
            for (let j = 0; j < q.capabilities.length; j++) {
                const capabilityId = q.capabilities[j].capabilityId;
                questionsWithCapabilities.push({ capabilityId, questionId: q.questionId })
            }
        }
        try {
            await meetingService.deleteQuestionsFromMeeting({ expertId, meetingDetailId, questionsWithCapabilities });
            setEnableMultipleSelection(false);
        }
        catch (e) {
            console.error(e);
        }
        await getMeetingQuestions();
        setSelectedQuestions([]);
        onDelete();
        Loader.hideLoader();
    }

    const onCollapse = (questionId: string) => {
        setActiveQuestion({ [questionId]: !activeQuestion[questionId] });
        setExpandedQuestion(undefined);
    }

    const openNextQuestion = () => {
        const currentQuestionInx = meetingQuestions.findIndex(q => activeQuestion[q.questionId]);
        if (currentQuestionInx > -1) {
            if (currentQuestionInx < meetingQuestions.length - 1) {
                setActiveQuestion({ [meetingQuestions[currentQuestionInx + 1].questionId]: true });
                setExpandedQuestion({ ...meetingQuestions[currentQuestionInx + 1] });
            } else {
                setActiveQuestion({});
                setExpandedQuestion(undefined);
                try{
                    scrollToSubmitBtn();
                } catch{}
            }
        }
    }

    const openPrevQuestion = () => {
        const currentQuestionInx = meetingQuestions.findIndex(q => activeQuestion[q.questionId]);
        if (currentQuestionInx > -1) {
            if (currentQuestionInx > 0) {
                setActiveQuestion({ [meetingQuestions[currentQuestionInx - 1].questionId]: true });
                setExpandedQuestion({ ...meetingQuestions[currentQuestionInx - 1] });
            } else {
                setActiveQuestion({});
                setExpandedQuestion(undefined);
            }
        }
    };

    const getMeetingQuestions = async () => {
        const result = await meetingService.getMeetingQuestions({ meetingDetailId, expertId });
        setMeetingQuestions(result.output);
    }

    const filterByNotEvaluated = (event: any) => {
        setShowNotEvaluatedQuestions(event.target.checked)
    }

    const handleRemoveSelection = () => {
        setEnableMultipleSelection((prevValue) => !prevValue);
        setSelectedQuestions([]);
    }

    const handleSubmissionClick = (questionId: string) => {
        toggleQuestionSelection(questionId)
    }

    useEffect(() => {
        getMeetingQuestions();
    }, [])

    return (
        <>
            <Row>
                <Col className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                        <Form.Check
                            inline
                            className="cursor-pointer"
                            onChange={filterByNotEvaluated}
                            checked={showNotEvaluatedQuestions}
                            label="Show Not Evaluated Question"
                            name="NotEvaluatedQuestions"
                            type="checkbox"
                            id={`NotEvaluatedQuestions`}
                        />
                    </div>
                    {!readonly && <div className="d-flex align-items-center">
                        <Button className="d-flex align-items-center btn-sm border-0 font-weight-bold p-2" style={{ backgroundColor: "green" }} onClick={() => setShowModuleSelectionModal(true)}><IconContainer color="white" icon={SelectMultiple} />{'Add Questions from Track'}</Button>
                        <Button className="d-flex align-items-center btn-sm ml-4 border-0 text-dark font-weight-bold p-2" style={{ backgroundColor: "lightgreen" }} onClick={() => setShowCustomQuestionModal(true)}><IconContainer color="black" icon={Plus} />{'Add Custom Questions'}</Button>
                        <Button className="d-flex align-items-center btn-sm btn-primary ml-4 border-0 font-weight-bold p-2" onClick={handleRemoveSelection}><IconContainer color="white" icon={enableMultipleSelection ? HideSource : ListCheck} />{enableMultipleSelection ? 'Remove Selection' : 'Select Multiple'}</Button>
                        {enableMultipleSelection && selectedQuestions.length > 0 && <Button className="d-flex align-items-center btn-sm btn-danger ml-4 border-0 font-weight-bold p-2" onClick={() => confirmBeforeDeleteSelected()}><IconContainer color="white" icon={TrashAlt} />{'Delete'}</Button>}
                    </div>}
                </Col>
            </Row>
            {meetingQuestions?.length ? meetingQuestions.filter(q => !showNotEvaluatedQuestions || q.questionStatus == "Not Evaluated").map((question: IMeetingQuestion, inx: number) =>
                <SubmissionContent
                    key={question.questionId}
                    onClick={() => enableMultipleSelection && handleSubmissionClick(question.questionId)}
                    className={`my-3 d-flex align-items-center`}
                    style={{
                        border: selectedQuestions.indexOf(question.questionId) > -1 ? '3px solid #5B94E3' : enableMultipleSelection ? '1px solid #5B94E3' : '0',
                        backgroundColor: activeQuestion[question.questionId] ? "" : question.questionStatus == "Not Evaluated" ? "#fff5f5" : "#f5fff5"
                    }}>
                    {enableMultipleSelection && <div className="custom-checkbox">
                        <Form.Check
                            inline
                            className="form-check-input"
                            onChange={(event: any) => toggleQuestionSelection(question.questionId)}
                            checked={selectedQuestions.indexOf(question.questionId) > -1}
                            name={`question${question.questionId}`}
                            type="checkbox"
                            id={`question${question.questionId}`}
                        />
                    </div>}
                    <div className="d-flex align-items-start col-12">
                        {!activeQuestion[question.questionId] && <div className="col-12 p-0">
                            <Row className="align-items-center">
                                <Col xs={7}>
                                    <EllipsedSpan>{inx + 1 + '. ' + question.title}</EllipsedSpan>
                                    <SmallSpan>{`Question`}</SmallSpan>
                                </Col>
                                <Col xs={5}>
                                    <Row>
                                        <Col sm="3">
                                            <NormalSpan>{question.containRecording ? 'Recorded' : 'Not Recorded'}</NormalSpan>
                                            <SmallSpan>{'Recording'}</SmallSpan>
                                        </Col>
                                        <Col sm="3">
                                            <NormalSpan>{QuestionTypes[question.questionType]}</NormalSpan>
                                            <SmallSpan>{'Question Type'}</SmallSpan>
                                        </Col>
                                        {question.capabilities?.length && <Col sm="4">
                                            <NormalSpan>{question.capabilities.map(q => q.capabilityName).join(", ")}</NormalSpan>
                                            <SmallSpan>{'Capability'}</SmallSpan>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-2 text-right">
                                    <div className="d-flex justify-content-end mr-2">
                                        {!readonly && question.questionType === "FOR_MEETING" && <Button type="button" className="btn-sm btn-warning d-flex mr-2" onClick={() => handleEditQuestion(question)}><IconContainer tooltip={"Edit Custom Question"} color="white" icon={Edit} />{"Edit Question"}</Button>}
                                        {!readonly && <Button type="button" className="btn-sm btn-danger d-flex" onClick={() => confirmBeforeDeleteSelected(question.questionId)}><IconContainer tooltip="Remove Question" color="white" icon={TrashAlt} />{'Remove'}</Button>}
                                        {!readonly && <Button type="button" className="btn-sm btn-success d-flex ml-2" onClick={() => handleRecordAnswer(question)}><IconContainer tooltip="Evaluate" color="white" icon={Microphone} />{'Record'}</Button>}
                                        {!readonly && <Button type="button" className="btn-sm btn-info d-flex ml-2" onClick={() => showQuestionEvaluations(question)}><IconContainer tooltip={"Evaluate"} color="white" icon={Highlight} />{'Evaluate'}</Button>}
                                        {readonly && <Button type="button" className="btn-sm btn-info d-flex ml-2" onClick={() => showQuestionEvaluations(question)}><IconContainer tooltip={"View Details"} color="white" icon={Expand} />{"View Details"}</Button>}
                                    </div>
                                </Col>
                            </Row>
                        </div>}
                        <Collapse in={activeQuestion[question.questionId]}>
                            <div className="w-100">
                                {expandedQuestion && expandedQuestion.questionId === question.questionId && serviceType && <MeetingEvaluation
                                    meetingDetailId={meetingDetailId}
                                    trackId={trackId}
                                    evaluated={question.questionStatus === "Evaluated"}
                                    capabilityId={question.capabilities[0].capabilityId}
                                    questionId={question.questionId}
                                    candidateId={candidateId}
                                    serviceType={serviceType}
                                    startRecording={startRecording[question.questionId]}
                                    onCollapse={() => onCollapse(question.questionId)}
                                    readonly={readonly}
                                    onSave={() => { getMeetingQuestions(); onQuestionEvaluated(); }}
                                    openNextQuestion={openNextQuestion}
                                    isFirstQuestion={inx == 0}
                                    isLastQuestion={inx == (meetingQuestions?.length - 1)}
                                    openPrevQuestion={openPrevQuestion}
                                />}
                            </div>
                        </Collapse>
                    </div>
                    {question.questionStatus == "Not Evaluated" ? <IconContainer
                        color={"#dc3545"}
                        height={'30px'}
                        icon={Star}
                        tooltip="Incomplete evaluation"
                        className="status-indecator"
                    /> : question.questionStatus == "Evaluated" && <IconContainer
                        color={"#28a745"}
                        height={'30px'}
                        icon={CheckCircle}
                        tooltip="Evaluated"
                        className="status-indecator"
                    />}
                </SubmissionContent>) : <div className="d-flex align-items-center justify-content-center mt-3 w-100 h5">
                {'Questions are not selected yet. Please add the questions for the meeting'}
            </div>}
            {(trackId && meetingDetailId) ? (showModuleSelectionModal &&
                <ModalComponent
                    className={"module-selector"}
                    show={showModuleSelectionModal}
                    handleClose={() => setShowModuleSelectionModal(false)}
                    header={'Select Questions for the Meeting'}
                >
                    <ModuleSelectionComponent
                        trackId={trackId}
                        meetingDetailId={meetingDetailId}
                        onClose={() => { setShowModuleSelectionModal(false); getMeetingQuestions(); onModuleSelectionClose() }}
                        expertHasSelectedQuestions={(meetingQuestions?.length || 0) > 0}
                    />
                </ModalComponent>)
                : <></>}
            {(trackId && meetingDetailId) ? (showCustomQuestionModal &&
                <ModalComponent
                    className="module-selector"
                    show={showCustomQuestionModal}
                    handleClose={() => { setCustomQuestionId(undefined); setShowCustomQuestionModal(false) }}
                >
                    <AddCustomQuestion
                        customQuestionId={customQuestionId}
                        trackId={trackId}
                        meetingDetailId={meetingDetailId}
                        onCancel={() => { setCustomQuestionId(undefined); setShowCustomQuestionModal(false) }}
                        onSave={() => { getMeetingQuestions(); setCustomQuestionId(undefined); setShowCustomQuestionModal(false); onQuestionAdd() }}
                    />
                </ModalComponent>)
                : <></>}

        </>
    )
}
